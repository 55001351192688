import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";
import MainRoutes from './MainRoutes';
import LoginRoute from './LoginRoute';

const AppRoutes = createBrowserRouter([
  ...MainRoutes, // Spread the MainRoutes array
  LoginRoute
]);

const AppRoutesProvider = () => (
  <I18nextProvider i18n={i18n}>
    {AppRoutes}
  </I18nextProvider>
);

export default AppRoutesProvider;
export { AppRoutes };