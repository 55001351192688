import { SearchOutlined, WhatsAppOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../axiosInstance";

import customContexts from "../services/customContexts";

const { Search } = Input;
const { Option } = Select;
const { Title, Paragraph } = Typography;

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const tailLayout = {
  wrapperCol: {
    span: 24,
  },
};

const buttonLayout = {
  wrapperCol: {
    offset: 6,
    span: 18,
  },
};

const Order = () => {
  const { customColors } = useContext(customContexts);

  const cardStyle = {
    backgroundColor: "white",
    border: `2px solid ${customColors.royalBlue.primary}`,
    borderRadius: "8px",
    padding: "16px",
    marginBottom: "16px",
  };

  const pastelCardStyle = {
    backgroundColor: "white",
    border: `2px solid ${customColors.royalBlue.primary}`,
    borderRadius: "8px",
    padding: "16px",
    marginBottom: "16px",
  };

  const [form] = Form.useForm();
  const [chosenOffer, setChosenOffer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [found, setFound] = useState(false);
  const { order } = useParams();
  useEffect(() => {
    console.log(order);
    order && form.setFieldsValue({ subscription: order });
  }, []);
  const onValueChange = (value) => {
    setChosenOffer(value);
  };

  const onFinish = async (values) => {
    console.log(values);
    let plan;
    switch (values.subscription) {
      case "3months":
        plan = " 3 mois";
        break;
      case "6months":
        plan = " 6 mois";
        break;
      default:
        plan = " 1 AN";
    }
    const message = `Nom : ${values.name}\nAbonnement : ${plan} ${
      onSearch(values.referralcode)
        ? `\n Code de parrainage : ` + values.referralcode
        : ""
    }`;

    const phonenumber = "+212698531586";
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phonenumber}&text=${encodeURIComponent(
      message
    )}`;

    // Ouvrir l'URL dans une nouvelle page
    window.location.href = whatsappUrl;
  };

  const onReset = () => {
    setFound(false);
    setChosenOffer(null);
    setLoading(false);
    form.resetFields();
  };

  const onSearch = async (value) => {
    setLoading(true);
    console.log(value);
    await axiosInstance
      .get(`/api/client/${value}`)
      .then((res) => {
        console.log("Données renvoyées:", res.data);
        res.data !== null ? setFound("1") : setFound("2");

        setLoading(false);
        return res.data !== null ? true : false;
      })
      .catch((error) => {
        setFound("2");
        setLoading(false);
      });
  };

  return (
    <div style={{ margin: "auto", padding: "16px" }}>
      <Space
        direction="vertical"
        style={{
          width: "100%",
          marginBottom: "16px",
        }}
      >
        <Title level={2} style={{ color: customColors.royalBlue.primary }}>
          Abonnez-vous pour accéder au moteur de recherche MFI AI
        </Title>
        <Alert
          description={
            <>
              <Title
                level={3}
                style={{ color: customColors.royalBlue.primary }}
              >
                Suivez ces étapes simples pour acheter votre abonnement :
              </Title>
              <Paragraph>
                <ol>
                  <li>
                    Remplissez le formulaire avec votre plan d'abonnement
                    souhaité
                  </li>
                  <li>
                    Cliquez sur le bouton "Envoyer la demande" pour initier
                    l'achat
                  </li>
                  <li>
                    Nous vous contacterons via WhatsApp pour discuter et
                    finaliser les détails
                  </li>
                </ol>
              </Paragraph>
              <Paragraph>
                Veuillez fournir des informations précises pour une transaction
                sans problème. Nous sommes actifs sur WhatsApp et répondrons
                rapidement.
              </Paragraph>
            </>
          }
          type="info"
          showIcon
          style={{ border: `2px solid ${customColors.royalBlue.primary}` }}
        />
      </Space>
      <div
        style={{
          margin: "auto",
          padding: "16px 0",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "800px",
        }}
      >
        <Card style={pastelCardStyle}>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item
              name="name"
              label="Nom"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Entrez votre nom" />
            </Form.Item>
            <Form.Item
              name="subscription"
              label="Abonnement"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Sélectionnez un plan d'abonnement"
                onChange={onValueChange}
                allowClear
                size="large"
                style={{ color: customColors.red.primary }}
              >
                <Option value="3months">PLAN DE BASE - 3 MOIS - 19,99€</Option>
                <Option value="6months">PLAN DE LUXE - 6 MOIS - 29,99€</Option>
                <Option value="12months">PLAN ULTIME - 1 AN - 49,99€</Option>
              </Select>
            </Form.Item>

            <Form.Item name="referralcode" label="Code de parrainage">
              <Search
                placeholder="(Entrez un code de parrainage si vous en avez un)"
                loading={loading}
                onSearch={onSearch}
                size="large"
                enterButton={
                  <Button
                    type="primary"
                    loading={loading}
                    onClick={onSearch}
                    style={{
                      backgroundColor: customColors.primaryColor,
                      color: customColors.royalBlue.primary,
                      borderColor: customColors.royalBlue.primary,
                    }}
                  >
                    Vérifier
                  </Button>
                }
                icon={<SearchOutlined />}
              />
            </Form.Item>

            {found === "1" ? (
              <Form.Item {...tailLayout}>
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <Alert
                    message="Code de parrainage valide"
                    description={
                      <>
                        <Paragraph>
                          <u>3 mois d'IPTV</u> = 🌟 <b>+1 mois gratuit</b>
                        </Paragraph>
                        <Paragraph>
                          <u>6 mois d'IPTV</u> = 🚀 <b>+3 mois gratuits</b>
                        </Paragraph>
                        <Paragraph>
                          <u>12 mois d'IPTV</u> = 💎 <b>+6 mois gratuits</b>
                        </Paragraph>
                      </>
                    }
                    type="success"
                    showIcon
                  />
                </Space>
              </Form.Item>
            ) : found === "2" ? (
              <Form.Item {...tailLayout}>
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <Alert
                    message="Code de parrainage invalide"
                    type="warning"
                    showIcon
                  />
                </Space>
              </Form.Item>
            ) : null}

            <Form.Item {...buttonLayout}>
              <Space>
                <Button
                  id="sendRequestButton"
                  icon={<WhatsAppOutlined />}
                  type="primary"
                  htmlType="submit"
                  className="card-with-border"
                  style={{
                    backgroundColor: "#25D366",
                    width: "100%",
                    color: customColors.royalBlue.primary,
                    borderColor: customColors.royalBlue.primary,
                  }}
                >
                  Envoyer la demande
                </Button>

                {/* <Button htmlType="button" onClick={onReset}>
                Reiniciar
              </Button> */}
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Order;
