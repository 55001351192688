import { DeleteOutlined, EditOutlined, PlusOutlined , ArrowLeftOutlined} from "@ant-design/icons";
import { 
  Layout, 
  Typography, 
  Button, 
  Table, 
  Modal, 
  Form, 
  Input, 
  Select, 
  DatePicker,
  Badge,
  message, 
  Image,
  Col,
  Card
} from "antd";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosInstance";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const { Content } = Layout;
const { Title } = Typography;

const ModelList = () => {
  const { t } = useTranslation();
  const [models, setModels] = useState([]);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingModel, setEditingModel] = useState(null);

  useEffect(() => {
    fetchModels();
  }, []);

  const fetchModels = async () => {
    try {
      const response = await axiosInstance.get("/model/models");
      setModels(response.data.models);
    } catch (error) {
      console.error(t('error.fetchModels'), error);
    }
  };

  const columns = [
    {
      title: t('name'),
      dataIndex: "modelname",
      key: "modelname",
      ellipsis: true,
      render: (text) => (
        <div className="table-cell-content" title={text}>
          {text}
        </div>
      ),
      fixed: "left",
    },
    {
      title: t('executionType'),
      dataIndex: "modelexecutetype",
      key: "modelexecutetype",
      ellipsis: true,
      render: (text) => (
        <div className="table-cell-content" title={text}>
          {text}
        </div>
      ),
    },
    {
      title: t('execution'),
      dataIndex: "modelexecute",
      key: "modelexecute",
      ellipsis: true,
      render: (text) => (
        <div className="table-cell-content" title={text}>
          {text}
        </div>
      ),
    },
    {
      title: t('collection'),
      dataIndex: "collectionname",
      key: "collectionname",
      ellipsis: true,
      render: (text) => (
        <div className="table-cell-content" title={text}>
          {text}
        </div>
      ),
    },
    {
      title: t('description'),
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      render: (text) => (
        <div className="table-cell-content" title={text}>
          {text}
        </div>
      ),
    },
    {
      title: t('creationDate'),
      dataIndex: "datecreation",
      key: "datecreation",
      ellipsis: true,
      render: (text) => (
        <div className="table-cell-content" title={text}>
          {text}
        </div>
      ),
    },
    {
      title: t('status'),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        switch (status) {
          case "active":
            return <Badge status="success" text={t('active')} />;
          case "not_active":
            return <Badge status="error" text={t('inactive')} />;
          case "under_development":
            return <Badge status="processing" text={t('underDevelopment')} />;
          default:
            return status;
        }
      },
    },
    {
      title: t('actions'),
      dataIndex: "",
      key: "actions",
      width: 250,
      fixed: "right",
      render: (text, record) => (
        <Button.Group>
          <Button type="dashed" onClick={() => handleEdit(record)}>
            <EditOutlined />
            {t('edit')}
          </Button>
          <Button danger onClick={() => handleDelete(record.id)}>
            <DeleteOutlined />
            {t('delete')}
          </Button>
        </Button.Group>
      ),
    },
  ];

  const showModal = () => setVisible(true);

  const handleCancel = () => {
    setVisible(false);
    setEditingModel(null);
    form.resetFields();
  };

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      values.datecreation = dayjs(values.datecreation).format("YYYY-MM-DD");
      values.status = values.status.toString();

      await axiosInstance.post("/model/models", values);
      message.success(t('success.createModel'));

      fetchModels();
      form.resetFields();
      setVisible(false);
    } catch (error) {
      message.error(t('error.createModel'));
      console.error(t('error.createModel'), error);
    }
  };

  const handleEdit = (model) => {
    const { datecreation, ...formData } = model;
    const formattedDate = dayjs(datecreation);
    setEditingModel({ ...formData, datecreation: formattedDate });
    form.setFieldsValue({ ...formData, datecreation: formattedDate });
    setVisible(true);
  };

  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
      values.datecreation = dayjs(values.datecreation).format("YYYY-MM-DD");

      // Ensure status is converted to a string
      values.status = values.status.toString();

      await axiosInstance.put(`/model/models/${editingModel.id}`, values);
      message.success(t('success.updateModel'));

      fetchModels();
      form.resetFields();
      setVisible(false);
    } catch (error) {
      message.error(t('error.updateModel'));
      console.error(t('error.updateModel'), error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/model/models/${id}`);
      message.success(t('success.deleteModel'));

      fetchModels();
    } catch (error) {
      message.error(t('error.deleteModel'));
      console.error(t('error.deleteModel'), error);
    }
  };

  return (
<Layout style={layoutStyle}>
      {/* <Col flex="0 1 auto">
        <Link to="/settings">
          <Image
            src="/logo/logo.png"
            alt="MFI Logo"
            preview={false}
            style={{
              height: "50px",
              width: "auto",
              marginTop: "10px",
            }}
          />
        </Link>
      </Col> */}
      <Content style={contentStyle}>
        <div style={{ width: "100%", padding: "20px" }}>
        <div style={{ display: 'flex' , alignItems: 'center' , alignItems: 'center', marginBottom: '20px'}}>
            <Button>
              <Link to="/settings">
                <ArrowLeftOutlined
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    color:"black"
                  }}
                />
              </Link>
            </Button>
          <Title level={2} style={{paddingLeft:"50px"}}>{t('models')}</Title>
          </div>
          <Card>
          <Button onClick={showModal} style={{ marginBottom: "20px" }}>
            <PlusOutlined />
            {t('addModel')}
          </Button>
          <Table
            dataSource={models}
            columns={columns}
            scroll={{ x: 1300 }}
            className="responsive-table"
          />
          </Card>
        </div>
      </Content>
      <Modal
        open={visible}
        title={editingModel ? t('editModel') : t('addModel')}
        onCancel={handleCancel}
        onOk={editingModel ? handleUpdate : handleCreate}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="modelname"
            label={t('name')}
            rules={[{ required: true, message: t('enterName') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="modelexecutetype"
            label={t('executionType')}
            rules={[
              {
                required: true,
                message: t('enterExecutionType'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="modelexecute"
            label={t('execution')}
            rules={[{ required: true, message: t('enterExecution') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="collectionname"
            label={t('collection')}
            rules={[
              { required: true, message: t('enterCollection') },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label={t('description')}
            rules={[
              { required: true, message: t('enterDescription') },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="datecreation"
            label={t('creationDate')}
            rules={[
              {
                required: true,
                message: t('selectCreationDate'),
              },
            ]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item
            name="status"
            label={t('status')}
            rules={[
              { required: true, message: t('selectStatus') },
            ]}
          >
            <Select>
              <Option value="active">{t('active')}</Option>
              <Option value="not_active">{t('inactive')}</Option>
              <Option value="under_development">{t('underDevelopment')}</Option>
            </Select>
          </Form.Item>
        </Form>
        </Modal>
      <style jsx>{`
        .responsive-table .ant-table-cell {
          white-space: normal;
          word-wrap: break-word;
        }
        .table-cell-content {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `}</style>
    </Layout>
  );
};

// Styles
const layoutStyle = {
  minHeight: "100vh",
  padding: "20px",
};

const contentStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  width: "100%",
  height: "100%",
};

export default ModelList;
