import {
  HomeOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Collapse,
  Input,
  Layout,
  Skeleton,
  Typography,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate , useLocation } from "react-router-dom";
import styled from "styled-components";
import axiosInstance from "../axiosInstance";
import HtmlModal from "../components/HtmlModal";
import SpinOverlay from "../components/SpinOverlay";
import i18n from "../i18n";
import customContexts from "../services/customContexts";

const { Header, Content } = Layout;
const { Search } = Input;
const { Text } = Typography;

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  background: #fff;
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const BackToHome = styled(Button)`
  position: absolute;
  left: 20px;
`;

const StyledSearch = styled(Search)`
  width: 50%;
  max-width: 600px;
`;

const FAQContent = styled(Content)`
  padding: 20px;
  background: #f0f2f5;
  min-height: 80vh;
`;

const AnswerCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
`;

const AnswerCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }
`;
const ColoredResponse = styled.span`
  color: ${(props) =>
    props.theme.primaryColor ||
    "#1890ff"}; // Use theme color or default to Ant Design's primary blue
  font-weight: bold;
`;

const FaqPage = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [htmlPages, setHtmlPages] = useState(null);
  const [query, setQuery] = useState(null);
  const initialSearchPerformed = useRef(false);
  const location = useLocation();

  const { t } = useTranslation();
  const { customColors, authenticated, setLang, Lang } =
    useContext(customContexts);

  const handleCardClickchat = (item) => {
    setIsLoading(true);

    setSelectedItem(item);
  };

  const getfilename = async (hyperlinks) => {
    try {
      console.log("hyperlinks///", hyperlinks);
      const obj = {
        hyperlinks: hyperlinks,
        langue: Lang,
      };
      await axiosInstance
        .post("/hyperlinks/get_pages", obj)
        .then((response) => {
          console.log("Extracting pages of hyperlinks:", response.data);
          setModalVisible(true);

          setHtmlPages(response.data);
        });
    } catch (error) {
      console.error("Error getting filename:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const FAQSkeleton = () => (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <div style={{ width: "100%", maxWidth: "1000px" }}>
        {[1, 2, 3, 4, 5].map((item) => (
          <div key={item} style={{ marginBottom: 24 }}>
            <Skeleton active title={{ width: "70%" }} paragraph={{ rows: 2 }} />
            <div style={{ marginLeft: 24, marginTop: 16 }}>
              <Skeleton.Input
                style={{ width: "30%", marginRight: 16 }}
                active
              />
              <Skeleton.Input
                style={{ width: "30%", marginRight: 16 }}
                active
              />
              <Skeleton.Input style={{ width: "30%" }} active />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const initialQuery = params.get('query');
    
    if (initialQuery) {
      setQuery(initialQuery);
      fetchQuestions(initialQuery);
    } else {
      fetchQuestions();
    }
  
    const storedLang = localStorage.getItem("lang");
    if (storedLang) {
      setLang(storedLang);
      i18n.changeLanguage(storedLang);
    }
  }, [location]);

  // useEffect(() => {
  //   fetchQuestions();
  //   const storedLang = localStorage.getItem("lang");
  //   if (storedLang) {
  //     setLang(storedLang);
  //     i18n.changeLanguage(storedLang);
  //   }
  // }, []);

  const fetchQuestions = async (query) => {
    const obj = { query: query, langue: Lang };
    try {
      if (query) {
        setQuestions([]);
        setLoading(true);
      }

      const response = await axiosInstance.post("/history-search/full", obj);
      setQuestions(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };


  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchQuestions(query);
    }
  };
  const handleBackToHome = () => {
    navigate("/");
    console.log("Navigating back to home");
  };

  const collapseItems = questions.map((faq, index) => ({
    key: index.toString(),
    label: (
      <span>
        {faq.question_original}{" "}
        {faq.reponse && <ColoredResponse>({faq.reponse})</ColoredResponse>}
      </span>
    ),
    children: (
      <AnswerCards>
        {faq.answers.map((answer, answerIndex) => (
          <AnswerCard
            key={answerIndex}
            hoverable
            onClick={() => {
              console.log("answer info:", answer);
              if (answer.source) {
                handleCardClickchat(answer);
                getfilename(answer.hyperlink);
              }
            }}
          >
            <Text>{answer.Paragraphe}</Text>
            <Text type="secondary">
              {t("faqPage.loi")}: {answer.La_loi}
            </Text>
          </AnswerCard>
        ))}
      </AnswerCards>
    ),
  }));

  return (
    <Layout>
      <StyledHeader
        style={{
          padding: "8px 24px",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          backgroundColor: "white",
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        }}
      >
        <BackToHome
          type="link"
          icon={<HomeOutlined />}
          onClick={handleBackToHome}
        >
          {t("faqPage.backToHome")}
        </BackToHome>
        <StyledSearch
          placeholder={t("faqPage.searchPlaceholder")}
          enterButton={
            loading ? (
              <LoadingOutlined />
            ) : (
              <Button icon={<SearchOutlined />}>{t("faqPage.search")}</Button>
            )
          }
          size="large"
          onSearch={fetchQuestions}
          onKeyDown={handleKeyPress}
          onChange={(e) => {
            console.log(e.target.value);
            setQuery(e.target.value);
          }}
          value={query}
        />
      </StyledHeader>
      <FAQContent style={{ marginTop: "63px" }}>
        {loading ? (
          <FAQSkeleton />
        ) : (
          <Collapse accordion defaultActiveKey={["0"]} items={collapseItems} />
        )}
      </FAQContent>
      {selectedItem ? (
        isLoading ? null : (
          <HtmlModal
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
            htmlFile={htmlPages}
            item={selectedItem}
            lang={Lang}
          />
        )
      ) : null}
      <SpinOverlay isLoading={isLoading} />
    </Layout>
  );
};

export default FaqPage;
