import {
    HistoryOutlined,
    LoginOutlined,
    LogoutOutlined,
    SettingOutlined,
  } from "@ant-design/icons";
  import { Avatar, Button, Card, Layout, Menu, Select, Tooltip } from "antd";
  import "flag-icons/css/flag-icons.min.css";
  import React, { useContext, useEffect, useRef, useState } from "react";
  import { useTranslation } from "react-i18next";
  import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
  import i18n from "../i18n";
  import customContexts from "../services/customContexts";
  import UserAvatar from "./UserAvatar"; // Import the new component
  

  const { Content, Footer } = Layout;
  const { Option } = Select;
  
  const SecondaryLayout = () => {
    const { t } = useTranslation();
    const { authenticated, setAuthenticated, setLang, Lang } =
      useContext(customContexts);
    const navigate = useNavigate();
    const location = useLocation();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);
    const avatarRef = useRef(null);
  
    const isHomePage = location.pathname === "/";
  
    const handleLogout = () => {
      localStorage.removeItem("token");
      setAuthenticated(false);
      navigate("/login");
    };
  
    const handleLanguageSwitch = (value) => {
      setLang(value);
      i18n.changeLanguage(value);
      localStorage.setItem("lang", value);
      navigate("/");
    };
  
    // const handleLanguageChange = (value) => {
    //   setLang(value);
    //   i18n.changeLanguage(value);
    //   localStorage.setItem("lang", value);
  
    //   // window.location.reload();
    // };
  
    useEffect(() => {
      const token = localStorage.getItem("token");
      setAuthenticated(token ? token : false);
    }, []);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target) &&
          avatarRef.current &&
          !avatarRef.current.contains(event.target)
        ) {
          setDropdownVisible(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
    // const toggleDropdown = () => {
    //   setDropdownVisible(!dropdownVisible);
    // };
  
    // const CustomDropdown = () => (
    //   <Card
    //     ref={dropdownRef}
    //     style={{
    //       width: 300,
    //       position: "absolute",
    //       top: "60px",
    //       right: "0px",
    //       zIndex: 9999,
    //       boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    //     }}
    //   >
    //     <div style={{ padding: "10px", borderBottom: "1px solid #f0f0f0" }}>
    //       <Avatar
    //         size={"large"}
    //         style={{
    //           backgroundColor: "#dda15e",
    //           color: "#fefae0",
    //           marginRight: "16px",
    //         }}
    //       >
    //         {authenticated[0].toUpperCase()}
    //       </Avatar>
    //       <span style={{ fontSize: "18px", fontWeight: "bold" }}>
    //         {authenticated}
    //       </span>
    //     </div>
    //     <Menu mode="vertical" style={{ border: "none" }} onClick={() => setDropdownVisible(false)}>
    //       <Menu.Item key="questions" icon={<HistoryOutlined />}>
    //         <Link to="/questions">{t("questionsHistory")}</Link>
    //       </Menu.Item>
    //       <Menu.Item key="settings" icon={<SettingOutlined />}>
    //         <Link to="/settings">{t("settings")}</Link>
    //       </Menu.Item>
    //       {/* <Menu.Item key="about" icon={<InfoCircleOutlined />}>
    //         <Link to="/about">About Us</Link>
    //       </Menu.Item> */}
    //       <Menu.Item
    //         key="logout"
    //         icon={<LogoutOutlined />}
    //         onClick={handleLogout}
    //         danger
    //       >
    //         {t("logout")}
    //       </Menu.Item>
    //     </Menu>
    //   </Card>
    // );
  
    const tooltipContent = (
      <div>
        <p style={{ margin: 0 }}>{authenticated}</p>
      </div>
    );
  
    const lawMenuItems = [
      {
        key: "1",
        label: location.pathname.includes("searchfr")
          ? t("searchInEnglishDocs")
          : t("searchInFrenchDocs"),
        onClick: () =>
          handleLanguageSwitch(
            location.pathname.includes("searchfr") ? "eng" : "fr"
          ),
      },
    ];
  
    const LawIcon = () =>
      location.pathname.includes("searchfr") ||
      location.pathname.includes("searchen") ? (
        // <Dropdown menu={{ items: lawMenuItems }} placement="bottomRight" arrow>
        //   <BookOutlined />
        // </Dropdown>
        <Select
          defaultValue={i18n.language}
          style={{
            width: 140,
            backgroundColor: "#ffffff",
            borderRadius: "8px",
            outline: "none",
            marginLeft: "16px",
          }}
          onChange={handleLanguageSwitch}
          size="large"
        >
          <Option value="fr">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={"/icons/Quebecflag.png"}
                alt="Quebec flag"
                style={{
                  width: "26px",
                  height: "auto",
                  marginRight: "8px",
                  verticalAlign: "middle",
                }}
              />
              <span>{t("french")}</span>
              {/* {Lang === "fr" && " ✓"} */}
            </div>
          </Option>
          <Option value="eng">
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                className="fi fi-ca"
                style={{ fontSize: "20px", marginRight: "8px" }}
              ></span>
              <span>{t("english")}</span>
              {/* {Lang === "eng" && " ✓"} */}
            </div>
          </Option>
        </Select>
      ) : null;
  
    return (
      <Layout
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        <Content
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              position: "fixed",
              top: 12,
              right: 24,
              zIndex: 9999,
              display: "flex",
              alignItems: "center",
            }}
          >
  
            {authenticated ? (
              <UserAvatar authenticated={authenticated} handleLogout={handleLogout} />
             ) : (
              <Button
                type="text"
                icon={<LoginOutlined />}
                onClick={() => navigate("/login")}
              >
                {t("login")}
              </Button>
            )}
          </div>
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>{t("footerText")}</Footer>
      </Layout>
    );
  };
  
  export default SecondaryLayout;
  