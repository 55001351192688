import React from 'react';
// import { Route } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import MinimalLayout from '../layouts/index';


const LoginRoute = {
    element: <MinimalLayout />,
    children: [
      {
        path: '/login',
        element: (
          <LoginPage />
        )
      }
    ]
}

export default LoginRoute;