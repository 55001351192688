import { Spin } from "antd";
import React from "react";
import ReactDOM from "react-dom";

const SpinOverlay = ({ isLoading }) => {
  if (!isLoading) return null;

  return ReactDOM.createPortal(
    <div className="spin-overlay">
      <Spin size="large" />
    </div>,
    document.body
  );
};

export default SpinOverlay;
