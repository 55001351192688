import { HomeOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Collapse, Input, Layout, Spin, Typography } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axiosInstance from "../axiosInstance";
import HtmlModal from "../components/HtmlModal";
import SpinOverlay from "../components/SpinOverlay";
import i18n from "../i18n";
import customContexts from "../services/customContexts";

const { Header, Content } = Layout;
const { Search } = Input;
const { Text } = Typography;

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  background: #fff;
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const BackToHome = styled(Button)`
  position: absolute;
  left: 20px;
`;

const StyledSearch = styled(Search)`
  width: 50%;
  max-width: 600px;
`;

const FAQContent = styled(Content)`
  padding: 20px;
  background: #f0f2f5;
  min-height: 80vh;
`;

const AnswerCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
`;

const AnswerCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }
`;

const Test = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [htmlPages, setHtmlPages] = useState(null);
  const [query, setQuery] = useState(null);
  const initialSearchPerformed = useRef(false);

  const { t } = useTranslation();
  const { customColors, authenticated, setLang, Lang } =
    useContext(customContexts);

  const handleCardClickchat = (item) => {
    setIsLoading(true);

    setSelectedItem(item);
  };

  const getfilename = async (hyperlinks) => {
    try {
      console.log("hyperlinks///", hyperlinks);
      const obj = {
        hyperlinks: hyperlinks,
        langue: Lang,
      };
      await axiosInstance
        .post("/hyperlinks/get_pages", obj)
        .then((response) => {
          console.log("Extracting pages of hyperlinks:", response.data);
          setModalVisible(true);

          setHtmlPages(response.data);
        });
    } catch (error) {
      console.error("Error getting filename:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchQuestions = async (query) => {
    const obj = { query: query, langue: Lang };
    try {
      const response = await axiosInstance.post("/history-search/full", obj);
      setQuestions(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
    const storedLang = localStorage.getItem("lang");
    if (storedLang) {
      setLang(storedLang);
      i18n.changeLanguage(storedLang);
    }
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchQuestions(query);
    }
  };
  const handleBackToHome = () => {
    navigate("/");
    console.log("Navigating back to home");
  };

  const collapseItems = questions.map((faq, index) => ({
    key: index.toString(),
    label: faq.question_original,
    children: (
      <AnswerCards>
        {faq.answers.map((answer, answerIndex) => (
          <AnswerCard
            key={answerIndex}
            onClick={() => {
              console.log("answer info:", answer);
              if (answer.source) {
                handleCardClickchat(answer);

                getfilename(answer.hyperlink);
              }
            }}
          >
            <Text>{answer.Paragraphe}</Text>
            <Text type="secondary">La loi: {answer.La_loi}</Text>
          </AnswerCard>
        ))}
      </AnswerCards>
    ),
  }));

  return (
    <Layout>
      <StyledHeader>
        <BackToHome
          type="link"
          icon={<HomeOutlined />}
          onClick={handleBackToHome}
        >
          Back to Home
        </BackToHome>
        <StyledSearch
          placeholder="Search FAQs"
          enterButton={<Button icon={<SearchOutlined />}>{t("search")}</Button>}
          size="large"
          onSearch={fetchQuestions}
          onKeyDown={handleKeyPress}
          onChange={(e) => {
            console.log(e.target.value);
            setQuery(e.target.value);
          }}
        />
      </StyledHeader>
      <FAQContent>
        {loading ? (
          <Spin tip="Loading FAQs..." size="large" />
        ) : (
          <Collapse accordion defaultActiveKey={["0"]} items={collapseItems} />
        )}
      </FAQContent>
      {selectedItem ? (
        isLoading ? null : (
          <HtmlModal
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
            htmlFile={htmlPages}
            item={selectedItem}
            lang={"eng"}
          />
        )
      ) : null}
      <SpinOverlay isLoading={isLoading} />
    </Layout>
  );
};

export default Test;
