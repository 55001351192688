import React from 'react';
import { Link } from 'react-router-dom';
import { Result, Button, Layout, Typography } from 'antd';
import { useTranslation } from "react-i18next";

const { Content } = Layout;
const { Title } = Typography;

const UnauthorizedPage = () => {
  const { t } = useTranslation();
  
  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>
      <Content style={{ padding: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ background: '#fff', padding: '24px', borderRadius: '8px', boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)' }}>
          <Result
            status="403"
            title={<Title level={2}>{t('unauthorizedTitle')}</Title>}
            subTitle={<Title level={4} style={{ fontWeight: 'normal' }}>{t('unauthorizedSubtitle')}</Title>}
            extra={
              <Link to="/">
                <Button type="primary" size="large">
                  {t('backToHome')}
                </Button>
              </Link>
            }
          />
        </div>
      </Content>
    </Layout>
  );
};

export default UnauthorizedPage;