import { Button, Result } from "antd";
import React from "react";
const Dashboard = () => {
  <Result
    status="warning"
    title="This page is under construction!"
    subTitle="Sorry for the inconvenience. This page is currently being developed."
    extra={
      <Button type="primary" key="home">
        Go Back Home
      </Button>
    }
  />;
};

export default Dashboard;
