import React, { useState } from "react";
import { Typography, Row, Col, Card, Input, Button, message } from "antd";
import axiosInstance from "../axiosInstance";

const { Text } = Typography;

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const validateForm = () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      message.error("Please fill in all fields");
      return false;
    }
    if (newPassword !== confirmPassword) {
      message.error("New password and confirm password do not match");
      return false;
    }
    if (newPassword.length < 8) {
      message.error("New password must be at least 8 characters long");
      return false;
    }
    return true;
  };

  const handleChangePassword = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await axiosInstance.post('/auth/change_password', {
        current_password: currentPassword,
        new_password: newPassword
      });

      if (response.status === 200) {
        message.success("Password changed successfully");
        handleCancel();
      } else {
        message.error("Failed to change password. Please try again.");
      }
    } catch (error) {
      console.error("Error changing password:", error);
      if (error.response && error.response.data && error.response.data.message) {
        message.error(error.response.data.message);
      } else {
        message.error("An error occurred while changing the password");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card title="Change Password">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Text strong>Current Password:</Text>
          <Input.Password 
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
        <Col span={24}>
          <Text strong>New Password:</Text>
          <Input.Password 
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
        <Col span={24}>
          <Text strong>Confirm New Password:</Text>
          <Input.Password 
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button onClick={handleCancel} style={{ marginRight: '8px' }}>Cancel</Button>
          <Button
            type="primary"
            style={{
              backgroundColor: "#F83E29",
              borderColor: "white",
              color: "white",
            }}
            onClick={handleChangePassword}
            loading={loading}
          >
            Change Password
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default ChangePassword;