// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-slider .slick-prev,
.custom-slider .slick-next {
  z-index: 1;
}

.custom-slider .slick-prev:before,
.custom-slider .slick-next:before {
  color: #000;
}

.custom-slider .slick-prev {
  left: 10px;
}

.custom-slider .slick-next {
  right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/SliderStyles.css"],"names":[],"mappings":"AAAA;;EAEE,UAAU;AACZ;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb","sourcesContent":[".custom-slider .slick-prev,\n.custom-slider .slick-next {\n  z-index: 1;\n}\n\n.custom-slider .slick-prev:before,\n.custom-slider .slick-next:before {\n  color: #000;\n}\n\n.custom-slider .slick-prev {\n  left: 10px;\n}\n\n.custom-slider .slick-next {\n  right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
