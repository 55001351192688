import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Divider,
  Form,
  Image,
  Input,
  Layout,
  List,
  Modal,
  Select,
  Spin,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import HtmlModal from "./HtmlModal";
import customContexts from "../services/customContexts";
import { parse } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

const { Content } = Layout;
const { Title, Text } = Typography;

const QuestionsPage = () => {
  const { customColors, authenticated , lang } = useContext(customContexts);
  const [questions, setQuestions] = useState([]);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [results, setResults] = useState([]);
  const [loadingResults, setLoadingResults] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [chosenQuestion, setChosenQuestion] = useState(null);
  const [chosenFeedback, setChosenFeedback] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalVisible2, setModalVisible2] = useState(false);
  const [formData, setFormData] = useState({ question: "", reference: "" });
  const [htmlPages, setHtmlPages] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState(""); // New state for search text
  const [filteredQuestions, setFilteredQuestions] = useState([]);

  useEffect(() => {
    if (authenticated) {
      fetchQuestions();
      fetchDocuments();
    }
  }, [authenticated]);

  useEffect(() => {
    handleSearch();
  }, [questions]);

  const fetchDocuments = async () => {
    try {
      const response = await axiosInstance.get("/document/documents/active");
      setDocuments(response.data.documents);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const fetchQuestionsByDocument = async (documentId) => {
    setLoadingQuestions(true);
    try {
      const response = await axiosInstance.get(
        `/question/questions/by-document/${documentId}/${authenticated}`
      );
      setQuestions(response.data);
      setFilteredQuestions(response.data);
    } catch (error) {
      setQuestions([]);
      setFilteredQuestions([]);
      console.error("Error fetching questions:", error);
    } finally {
      setLoadingQuestions(false);
    }
  };

  const getUser = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  };

  console.log(getUser().username)

  const fetchQuestions = async () => {
    setLoadingQuestions(true);
    try {
      const response = await axiosInstance.get(
        `/question/users/${getUser().username}/questions`
      );
      const reversedQuestions = response.data.reverse();
      setQuestions(reversedQuestions);
      setFilteredQuestions(reversedQuestions); // Initialize filteredQuestions with all questions
    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      setLoadingQuestions(false);
    }
  };

  const handleQuestionSelect = async (questionId) => {
    setSelectedQuestion(questionId);
    try {
      const response = await axiosInstance.get(
        `/feedback/by_question/${questionId}`
      );
      setResults(response.data.feedbacks);
    } catch (error) {
      console.error("Error fetching results:", error);
    } finally {
      setLoadingResults(false);
    }
  };

  const handleDeleteQuestion = async (answerId) => {
    try {
      await axiosInstance.delete(`/question/question/${answerId}`);
      setSelectedQuestion(null);
      
      // Remove the deleted question from questions
      const updatedQuestions = questions.filter(q => q.id !== answerId);
      setQuestions(updatedQuestions);
      
      // Update filteredQuestions based on current filters
      handleSearch();
      
      message.success(t("deleteSuccess"));
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };

  const handleDeleteAnswer = (answerId) => {
    setChosenFeedback(answerId);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axiosInstance.delete(`/feedback/${chosenFeedback}`);
      handleQuestionSelect(chosenQuestion.id);
      message.success(t("deleteAnswerSuccess"));
    } catch (error) {
      console.error("Error deleting answer:", error);
    }
    setDeleteModalVisible(false);
  };

  const handleItemClick = (item) => {
    let parsedFilters;
    try {
      parsedFilters = JSON.parse(item.filters);
    } catch (error) {
      parsedFilters = null;
    }
    console.log("selected question:", item);
    setChosenQuestion({
      id: item.id,
      texte: item.texte,
      filters: parsedFilters,
      langue: item.langue,
    });
    handleQuestionSelect(item.id);
  };

  const handleFormSubmit = async () => {
    try {
      const feedbackData = {
        reference: formData.reference,
        question_id: chosenQuestion.id,
      };
      await axiosInstance.post("/feedback/save", feedbackData);
      handleQuestionSelect(chosenQuestion.id);
      message.success(t("addAnswerSuccess"));
      setFormData({ question: "", reference: "" });
      setModalVisible2(false);
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire:", error);
    }
  };

  const formatQuebecTime = (dateString) => {
    // console.log("time:", dateString);
    const quebecTimeZone = "America/Toronto"; // Quebec uses the same time zone as Toronto

    // Parse the input date string
    const parsedDate = parse(dateString, "dd/MM/yyyy | HH:mm", new Date());

    // Format the date in Quebec time
    return formatInTimeZone(parsedDate, quebecTimeZone, "dd/MM/yyyy | HH:mm");
  };

  const DynamicDescriptions = ({ data }) => {
    return (
      data && (
        <Descriptions>
          {Object.values(data).map((item) => (
            <Descriptions.Item key={item.id} label={item.name}>
              {item.filter_values.map((fv, index) => (
                <span key={index}>{fv.value}</span>
              ))}
            </Descriptions.Item>
          ))}
        </Descriptions>
      )
    );
  };

  const handleCardClickchat = (item) => {
    setIsLoading(true);

    setSelectedItem(item);
  };

  const getfilename = async (hyperlinks) => {
    try {
      console.log("hyperlinks///", hyperlinks);
      const obj = {
        hyperlinks: hyperlinks,
        langue: chosenQuestion.langue === "en" ? "eng" : chosenQuestion.langue,
      };
      await axiosInstance
        .post("/hyperlinks/get_pages", obj)
        .then((response) => {
          console.log("Extracting pages of hyperlinks:", response.data);
          setModalVisible(true);

          setHtmlPages(response.data);
        });
    } catch (error) {
      console.error("Error getting filename:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    let filteredResults = questions;
    
    // Filter by document if one is selected
    if (selectedDocument) {
      filteredResults = filteredResults.filter(question => question.document_id === selectedDocument);
    }
    
    // Filter by search text
    if (searchText) {
      const lowercasedSearch = searchText.toLowerCase();
      filteredResults = filteredResults.filter(question => 
        question.texte.toLowerCase().includes(lowercasedSearch)
      );
    }
    
    setFilteredQuestions(filteredResults);
  };

  const reponse= t("response")
  return (
    <Layout style={{ minHeight: "100vh", padding: "20px" }}>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          paddingBottom:"20px"
        }}
      >
        <Link to="/">
          <Image
            src="/logo/logo.png"
            alt="MFI Logo"
            preview={false}
            style={{
              height: "40px",
              marginRight: "20px",
              width: "auto",
            }}
          />
        </Link>

      </div> */}
        <Title level={3} style={{ paddingLeft: "20px", paddingBottom:"20px" }}>
          {t("HistoriquedesQuestions")}
        </Title>
      <div style={{ display: "flex", paddingBottom: "20px" ,gap: "20px" }}>
        <Input
          placeholder={t("homePage.searchPlaceholder")}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          size="large"
          style={{ width: "30%" }}
        />
        <Select
          showSearch
          placeholder={t("searchByDocument")}
          optionFilterProp="children"
          onChange={(value) => setSelectedDocument(value)}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          size="large"
          style={{ width: "30%" }}
          defaultValue=""
        >
          <Select.Option value="" style={{color: "#808080"}}>
            {t("selectDocument")}
          </Select.Option>
          {documents.map((document) => (
            <Select.Option key={document.id} value={document.id}>
              {document.name}
            </Select.Option>
          ))}
        </Select>
        <Button type="primary" onClick={handleSearch} size="large" style={{backgroundColor: "#F83E29", color: "white",}}>
          {t("search")}
        </Button>
      </div>

      <div
        style={{ display: "flex", gap: "20px", height: "calc(100vh - 100px)" }}
      >
        {/* Sidebar Box */}
        <div
          style={{
            flex: "0 0 30%",
            background: "#fff",
            borderRadius: "8px",
            boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Divider style={{ margin: "0" }} />
          <div style={{marginLeft: "20px"}}>
            <Title level={4}>{t("questions")}</Title>
          </div>
          <div style={{ padding: "20px", overflowY: "auto", flex: 1 }}>
          {loadingQuestions ? (
            <Spin />
          ) : filteredQuestions.length === 0 ? (
            <Text>{t("noQuestionsFound")}</Text>
          ) : (
            <List
              dataSource={filteredQuestions}
              renderItem={(item) => (
                <List.Item
                  onClick={() => handleItemClick(item)}
                  style={{ cursor: "pointer" }}
                  className="hover-blue"
                  actions={[
                    <Button
                      type="link"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteQuestion(item.id);
                      }}
                    />,
                  ]}
                >
                  <List.Item.Meta
                    title={item.texte}
                    description={formatQuebecTime(item.date)}
                  />
                </List.Item>
              )}
            />
          )}
          </div>
        </div>

        {/* Content Box */}
        <div
          style={{
            flex: "1",
            background: "#fff",
            borderRadius: "8px",
            boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Content style={{ padding: "20px", overflowY: "auto", flex: 1 }}>
            {selectedQuestion ? (
              <>
                <Title level={4}>"{t("response")} {chosenQuestion.texte}" </Title>
                <DynamicDescriptions data={chosenQuestion.filters} />
                <Divider />
                {loadingResults ? (
                  <Spin />
                ) : Array.isArray(results) && results.length === 0 ? (
                  <>
                    <Text>{t("noAnswersFound")}</Text>
                    <Divider />
                    <Button
                      type="dashed"
                      onClick={() => setModalVisible2(true)}
                    >
                      <PlusOutlined /> {t("addAnswer")}
                    </Button>
                  </>
                ) : (
                  <>
                    <List
                      dataSource={results}
                      renderItem={(item) => (
                        <List.Item
                          actions={[
                            <DeleteOutlined
                              onClick={() => handleDeleteAnswer(item.id)}
                            />,
                          ]}
                        >
                          <List.Item.Meta
                            className={item.source && "hoverable-paragraph"}
                            onClick={() => {
                              console.log("item info:", item);
                              if (item.source) {
                                handleCardClickchat(item);

                                getfilename(item.hyperlink);
                              }
                            }}
                            title={`Reference: ${item.reference}`}
                            description={
                              item.source
                                ? `Modele: ${item.source}`
                                : `{Réponse générée par} ${authenticated}`
                            }
                          />
                        </List.Item>
                      )}
                    />
                    <Divider />
                    <div>
                      <Button
                        type="dashed"
                        onClick={() => setModalVisible2(true)}
                        style={{ maxWidth: 600 }}
                      >
                        <PlusOutlined />
                        {t("addAnswer")}
                      </Button>
                    </div>
                  </>
                )}
              </>
            ) : (
              <Text>
                {t("ResponseShowed")}
              </Text>
            )}
          </Content>
        </div>
      </div>

      {/* Modals */}
      <Modal
        open={modalVisible2}
        onCancel={() => setModalVisible2(false)}
        footer={[
          <Button key="back" onClick={() => setModalVisible2(false)}>
            {t("cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={handleFormSubmit}>
            {t("send")}
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Question">
            <Input disabled value={chosenQuestion && chosenQuestion.texte} />
          </Form.Item>
          <Form.Item label="Reference">
            <Input
              value={formData.reference}
              onChange={(e) =>
                setFormData({ ...formData, reference: e.target.value })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={t("ConfirmeDeletion")}
        open={deleteModalVisible}
        onOk={handleDeleteConfirm}
        onCancel={() => setDeleteModalVisible(false)}
      >
        {t("confirmDelete")}
      </Modal>

      {selectedItem ? (
        isLoading ? null : (
          <HtmlModal
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
            htmlFile={htmlPages}
            item={selectedItem}
            lang={chosenQuestion.langue}
          />
        )
      ) : null}

      {/* {selectedItem && (
        <HtmlModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          htmlFile={htmlPages}
          item={selectedItem}
        />
      )} */}
    </Layout>
  );
};

export default QuestionsPage;
