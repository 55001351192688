// export const JSON_API = 'https://bleusky.herokuapp.com/api'

// export const JSON_API = "https://localhost:7232/api";
// export const JSON_API_Report = "https://localhost:7232/api";
// export const JSON_API_logo = "https://localhost:7232/";

// export const JSON_API = 'http://173.209.40.32:81/api' // 3.0

// export const JSON_API = "http://173.209.40.32:311/api"; //3.1
// export const JSON_API_Report = "http://173.209.40.32:311/api";

// export const JSON_API = "http://173.209.40.32:6071/api"; //3.3

// export const JSON_API = "http://173.209.40.32:311/api";
// export const JSON_API_Report = "http://173.209.40.32:311/api";
// export const JSON_API_logo = "http://173.209.40.32:311/";

export const JSON_API = "https://mfi-logiciel.com/api";

// export const JSON_API = "https://referralsystem-api.onrender.com";
// export const JSON_API = "http://173.209.40.46:8081";
// export const JSON_API = "https://99e6-41-141-215-198.ngrok-free.app";
// export const JSON_API = "http://173.209.40.46:8081";
