import React from 'react';
import RouteGuard from "../services/RouteGuard";
import MFIhomepage from "../pages/MFIhomepage";
import QuestionsPage from "../components/QuestionsPage";
import Dashboard from "../pages/Dashboard";
import Order from "../pages/Order";
import Settings from "../pages/Settings";
import Test from "../pages/test";
import FaqPage from "../pages/FaqPage";
import Searchengine from "../pages/Searchengine";
import VisitorsPage from "../pages/VisitorsPage";
import NotFoundPage from "../pages/NotFoundPage";
import AppearancePage from "../components/AppearancePage";
import DocumentListPage from "../components/DocumentList";
import ModelListPage from "../components/ModelList";
import DocumentModelListPage from "../components/DocumentModelList";
import JurisprudenceList from "../components/JurisprudenceList"
import FilterListPage from "../components/FilterList";
import MainLayout from "../layouts/MainLayout";
import SecondaryLayout from "../layouts/SecondaryLayout";
import ProfilePage from '../pages/ProfilePage';
import KnowledgeTable from '../components/KnowledgeTable';
import UserList from "../components/UserList";
import UnauthorizedPage from "../pages/UnauthorizedPage";

const MainRoutes = [
  {
    element: <MainLayout><RouteGuard /></MainLayout>,
    children: [
      {
        path: "/",
        element: <RouteGuard><MFIhomepage /></RouteGuard>
      },
      {
        path: "questions",
        element: <RouteGuard><QuestionsPage /></RouteGuard>
      },
      {
        path: "dashboard",
        element: <RouteGuard allowedRoles={['Admin']}><Dashboard /></RouteGuard>
      },
      {
        path: "order",
        element: <RouteGuard><Order /></RouteGuard>
      },
      {
        path: "order/:order",
        element: <RouteGuard><Order /></RouteGuard>
      },
      {
        path: "test",
        element: <RouteGuard allowedRoles={['Admin']}><Test /></RouteGuard>
      },
      {
        path: "profile",
        element: <RouteGuard><ProfilePage /></RouteGuard>
      },
      {
        path: "settings",
        element: <RouteGuard allowedRoles={['admin']}><Settings /></RouteGuard>,
      },
      {
        path: "settings/appearance",
        element: <RouteGuard allowedRoles={['admin']}><AppearancePage /></RouteGuard>,
      },
      {
        path: "settings/documents",
        element: <RouteGuard allowedRoles={['admin']}><DocumentListPage /></RouteGuard>,
      },
      {
        path: "settings/models",
        element: <RouteGuard allowedRoles={['admin']}><ModelListPage /></RouteGuard>,
      },
      {
        path: "settings/document-models",
        element: <RouteGuard allowedRoles={['admin']}><DocumentModelListPage /></RouteGuard>,
      },
      {
        path: "settings/filters",
        element: <RouteGuard allowedRoles={['admin']}><FilterListPage /></RouteGuard>,
      },
      {
        path: "settings/jurisprudance",
        element: <RouteGuard allowedRoles={['admin']}><JurisprudenceList /></RouteGuard>,
      },
      {
        path: "settings/knowledge",
        element: <RouteGuard allowedRoles={['admin']}><KnowledgeTable /></RouteGuard>,
      },
      {
        path: "settings/users",
        element: <RouteGuard allowedRoles={['admin']}><UserList /></RouteGuard>,
      },
      {
        path: "unauthorized",
        element: <RouteGuard><UnauthorizedPage /></RouteGuard>
      },
      {
        path: "*",
        element: <RouteGuard><NotFoundPage /></RouteGuard>
      },
    ],
  },
  {
    element: <SecondaryLayout><RouteGuard /></SecondaryLayout>,
    children: [
      {
        path: "searchen",
        element: <RouteGuard><Searchengine /></RouteGuard>
      },
      {
        path: "searchfr",
        element: <RouteGuard><VisitorsPage /></RouteGuard>
      },
    ],
  },
  {
    element: <SecondaryLayout><RouteGuard /></SecondaryLayout>,
    children: [
      {
        path: "faq",
        element: <RouteGuard><FaqPage /></RouteGuard>
      },
    ],
  },
];

export default MainRoutes;