import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const RouteGuard = ({ children, allowedRoles = [] }) => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          // Here you would typically validate the token with your backend
          // For this example, we're just checking if it exists
          setAuthenticated(true);
          
          // Check user role
          const user = JSON.parse(localStorage.getItem('user'));
          if (user && user.role) {
            if (allowedRoles.length === 0 || allowedRoles.includes(user.role.name)) {
              setAuthorized(true);
            } else {
              setAuthorized(false);
              // Redirect to an unauthorized page or show an error message
              navigate("/unauthorized");
            }
          } else {
            setAuthorized(false);
            navigate("/login");
          }
        } else {
          navigate("/login");
        }
      } catch (error) {
        console.error("Authentication error:", error);
        setAuthenticated(false);
        setAuthorized(false);
        navigate("/login");
      }
    };

    checkAuth();
  }, [navigate, allowedRoles]);

  if (authenticated && authorized) {
    return children;
  }

  // You can return null or a loading indicator here
  return null;
};

export default RouteGuard;