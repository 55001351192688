import React, { useState, useEffect } from "react";
import { Table, Typography, Layout, Button, Row, Col, Modal, Form, Input, Select, InputNumber, Card ,Badge , message , Popconfirm} from "antd";
import { EyeOutlined, DeleteOutlined, EditOutlined, PlusOutlined, StopOutlined, LeftOutlined, RightOutlined , ArrowLeftOutlined ,UserOutlined, MailOutlined , LockOutlined } from "@ant-design/icons";
// import axiosInstance from "../axiosInstance";
// import TextArea from "antd/es/input/TextArea";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axiosInstance from "../axiosInstance";



const { Title } = Typography;
const { Content } = Layout;
const { Option } = Select;

const UserTable = () => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [form] = Form.useForm();
  const [modifyUserVisible, setModifyUserVisible] = useState(false);
  const [modifyForm] = Form.useForm();
  const [viewForm] = Form.useForm();





  // Example users
  // const exampleUsers = [
  //   { key: 1, name: "John Doe", email: "john@example.com", role: "Admin", dateJoined: "2023-01-01" },
  //   { key: 2, name: "Jane Smith", email: "jane@example.com", role: "User", dateJoined: "2023-02-15" },
  //   { key: 3, name: "Bob Johnson", email: "bob@example.com", role: "User", dateJoined: "2023-03-10" },
  //   { key: 4, name: "Alice Brown", email: "alice@example.com", role: "Moderator", dateJoined: "2023-04-20" },
  //   { key: 5, name: "John Doe", email: "john@example.com", role: "Admin", dateJoined: "2023-01-01" },
  //   { key: 6, name: "Jane Smith", email: "jane@example.com", role: "User", dateJoined: "2023-02-15" },
  //   { key: 7, name: "Bob Johnson", email: "bob@example.com", role: "User", dateJoined: "2023-03-10" },
  //   { key: 8, name: "Alice Brown", email: "alice@example.com", role: "Moderator", dateJoined: "2023-04-20" },
  // ];

  useEffect(() => {
    getUsers();
  }, []);

  const paginatedData = dataSource.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const createUser = async (userData) => {
    try {
      const response = await axiosInstance.post('/user/users', userData);
      message.success(t('userAddedSuccessfully'));
      getUsers(); // Refresh the user list
      return response.data;
    } catch (error) {
      console.error("Failed to create user:", error);
      message.error(t('failedToAddUser'));
      throw error;
    }
  }


  const getUsers = async () => {
    try {
      const response = await axiosInstance.get('/user/users');
      const formattedUsers = response.data.map(user => ({
        key: user.id,
        firstname:user.firstname,
        lastname:user.lastname,
        name: user.username,
        email: user.email,
        role: user.role.name,
        is_blocked: user.is_blocked,
      }));
      setDataSource(formattedUsers);
      setTotal(formattedUsers.length);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  const handleModifyUser = async (values) => {
    try {
      const modifiedValues = {
        ...values,
        is_blocked: values.is_blocked === 'true'
      };
      await axiosInstance.put(`/user/users/${modifiedValues.id}`, modifiedValues);
      message.success(t('userModifiedSuccessfully'));
      getUsers(); // Refresh the user list
      setModifyUserVisible(false);
    } catch (error) {
      console.error("Failed to modify user:", error);
      message.error(t('failedToModifyUser'));
    }
  };

  const handleViewUserUpdate = async (values) => {
    try {
      const modifiedValues = {
        ...values,
        is_blocked: values.is_blocked === 'true'
      };
      await axiosInstance.put(`/user/users/${modifiedValues.id}`, modifiedValues);
      message.success(t('userModifiedSuccessfully'));
      getUsers(); // Refresh the user list
      setVisible(false);
    } catch (error) {
      console.error("Failed to modify user:", error);
      message.error(t('failedToModifyUser'));
    }
  };


  // Handle row selection
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const showAddUserModal = () => {
    setAddUserVisible(true);
  };

  // Handlers for action buttons
  const handleEdit = (record) => {
    setSelectedRecord(record);
    modifyForm.setFieldsValue({
      id: record.key,
      firstname: record.firstname,
      lastname: record.lastname,
      email: record.email,
      username: record.name,
      role: record.role,
      is_blocked: record.is_blocked.toString() // Convert boolean to string
    });
    setModifyUserVisible(true);
  };

  const handleShow = (record) => {
    setSelectedRecord(record);
    viewForm.setFieldsValue({
      id: record.key,
      firstname: record.firstname,
      lastname: record.lastname,
      email: record.email,
      username: record.name,
      role: record.role,
      is_blocked: record.is_blocked.toString()
    });
    setVisible(true);
  };

  const handleDelete = async (userId) => {
    try {
      await deleteUser(userId);
    } catch (error) {
      // Error is already handled in deleteUser function
    }
  };

  const handleBulkDelete = () => {
    Modal.confirm({
      title: t('confirmBulkDelete'),
      content: t('bulkDeleteWarning'),
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk: async () => {
        try {
          await Promise.all(selectedRowKeys.map(userId => deleteUser(userId)));
          setSelectedRowKeys([]);
        } catch (error) {
          // Errors are already handled in deleteUser function
        }
      },
    });
  };

  const handleBlockUsers = () => {
    console.log('Block Users', selectedRowKeys);
    // Implement block users functionality here
  };

  const handleAddUser = async (values) => {
    try {
      const userData = {
        username: values.email, // Using email as username
        password: values.password,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        role: values.role
      };
      await createUser(userData);
      setAddUserVisible(false);
      form.resetFields();
    } catch (error) {
      // Error is already handled in createUser function
    }
  };

  const deleteUser = async (userId) => {
    try {
      await axiosInstance.delete(`/user/users/${userId}`);
      message.success(t('userDeletedSuccessfully'));
      getUsers(); // Refresh the user list
    } catch (error) {
      console.error("Failed to delete user:", error);
      message.error(t('failedToDeleteUser'));
    }
  };

  const handleClose = () => {
    setVisible(false);
    setSelectedRecord(null);
  };

  const handleAddUserCancel = () => {
    setAddUserVisible(false);
    form.resetFields();
  };

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle page size change
  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1); // Reset to first page when page size changes
  };

  // Configure rowSelection
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // Define equal width for columns
  const columnWidth = 150; // Adjust this value as needed

  // All columns configuration for the table
  const allColumns = [
    {
      title: t('firstName'),
      dataIndex: 'firstname',
      key: 'firstname',
      width: columnWidth,
    },
    {
      title: t('lastName'),
      dataIndex: 'lastname',
      key: 'lastname',
      width: columnWidth,
    },
    {
      title: t('username'),
      dataIndex: 'name',
      key: 'username',
      width: columnWidth,
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
      width: columnWidth,
    },
    {
      title: t('role'),
      dataIndex: 'role',
      key: 'role',
      width: columnWidth,
    },
    {
      title: t('status'),
      dataIndex: "is_blocked",
      key: "status",
      width: columnWidth,
      render: (is_blocked) => {
        if (is_blocked) {
          return <Badge status="error" text={t('blocked')} />;
        } else {
          return <Badge status="success" text={t('active')} />;
        }
      },
    },
    {
      title: t('action'),
      key: 'action',
      width: columnWidth,
      render: (text, record) => (
        <Button.Group>
          <Button onClick={() => handleShow(record)}>
            <EyeOutlined />
            {t('show')}
          </Button>
          <Button onClick={() => handleEdit(record)}>
            <EditOutlined />
            {t('edit')}
          </Button>
          <Popconfirm
            title={t('deleteUserConfirmation')}
            onConfirm={() => handleDelete(record.key)}
            okText={t('yes')}
            cancelText={t('no')}
          >
            <Button danger>
              <DeleteOutlined />
              {t('delete')}
            </Button>
          </Popconfirm>
        </Button.Group>
      ),
    },
  ];

  return (
    <Layout style={layoutStyle}>
      <Content style={contentStyle}>
        <div style={{ width: "100%", padding: "20px" }}>
        <div style={{ display: 'flex' , alignItems: 'center' , alignItems: 'center', marginBottom: '20px'}}>
            <Button>
              <Link to="/settings">
                <ArrowLeftOutlined
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    color:"black"
                  }}
                />
              </Link>
            </Button>
          <Title level={2} style={{paddingLeft:"50px"}}>{t("userManagement")}</Title>
        </div>
          <Card>
            <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
              <Col>
                <Button onClick={showAddUserModal}>
                  <PlusOutlined /> {t('addUser')}
                </Button>
              </Col>
              {selectedRowKeys.length >= 2 && (
                <Col>
                  {/* <Button onClick={handleBlockUsers} style={{ marginRight: '8px' }}>
                    <StopOutlined /> {t('blockUsers')}
                  </Button> */}
                  <Button danger onClick={handleBulkDelete}>
                    <DeleteOutlined /> {t('bulkDelete')}
                  </Button>
                </Col>
              )}
            </Row>
            <Table
              rowSelection={{
                selectedRowKeys,
                onChange: onSelectChange,
              }}
              dataSource={paginatedData}
              columns={allColumns}
              pagination={
                true
              }
              scroll={{ x: 'max-content' }}
            />
          </Card>
          {/* <Row justify="start" align="middle" style={{ marginTop: '16px' }}>
            <Col>
              <Button
                onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                disabled={currentPage === 1}
                icon={<LeftOutlined />}
              />
            </Col>
            <Col style={{ padding: '0 8px' }}>
              <InputNumber
                min={1}
                max={Math.ceil(total / pageSize)}
                value={currentPage}
                onChange={handlePageChange}
                style={{ width: 60, textAlign: 'center' }}
              />
            </Col>
            <Col>
              <Button
                onClick={() => handlePageChange(Math.min(currentPage + 1, Math.ceil(total / pageSize)))}
                disabled={currentPage === Math.ceil(total / pageSize)}
                icon={<RightOutlined />}
              />
            </Col>
            <Col style={{ marginLeft: '16px' }}>
              <Select value={pageSize} onChange={handlePageSizeChange} style={{ width: 120 }}>
                <Option value={6}>6 / page</Option>
                <Option value={12}>12 / page</Option>
                <Option value={24}>24 / page</Option>
              </Select>
            </Col>
          </Row> */}
          {/* Updated View User Details Modal */}
        <Modal
          title={<div style={{ marginBottom: '30px' }}>{t('viewUserDetails')}</div>}
          open={visible}
          onCancel={handleClose}
          footer={null}
          width={800}
        >
          {selectedRecord && (
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t('firstName')}>
                    <Input prefix={<UserOutlined />} value={selectedRecord.firstname} readOnly />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('lastName')}>
                    <Input prefix={<UserOutlined />} value={selectedRecord.lastname} readOnly />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t('email')}>
                    <Input prefix={<MailOutlined />} value={selectedRecord.email} readOnly />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('username')}>
                    <Input prefix={<UserOutlined />} value={selectedRecord.name} readOnly />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t('role')}>
                    <Input value={selectedRecord.role} readOnly />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('userStatus')}>
                    <Input value={selectedRecord.is_blocked ? t('blocked') : t('active')} readOnly />
                  </Form.Item>
                </Col>
              </Row>
              <Button onClick={handleClose}>{t('close')}</Button>
            </Form>
          )}
        </Modal>

          {/* Add User Modal */}
        <Modal
          title={<div style={{ marginBottom: '30px' }}>{t('addNewUser')}</div>}
          open={addUserVisible}
          onCancel={handleAddUserCancel}
          footer={null}
          width={800}
        >
          <Form
            form={form}
            name="add_user"
            onFinish={handleAddUser}
            layout="vertical"
          >
            <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="firstname"
                label={t('firstName')}
                rules={[{ required: true, message: t('pleaseInputFirstName') }]}
              >
                <Input prefix={<UserOutlined />} placeholder={t('firstName')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="lastname"
                label={t('lastName')}
                rules={[{ required: true, message: t('pleaseInputLastName') }]}
              >
                <Input prefix={<UserOutlined />} placeholder={t('lastName')} />
              </Form.Item>
            </Col>
            </Row>
            <Row gutter={16}>
            <Col span={12}>
                <Form.Item
                  name="email"
                  label={t('email')}
                  rules={[
                    { required: true, message: t('pleaseInputEmail') },
                    { type: 'email', message: t('invalidEmail') }
                  ]}
                >
                  <Input prefix={<MailOutlined />} placeholder={t('email')} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="password"
                  label={t('password')}
                  rules={[{ required: true, message: t('pleaseInputPassword') }]}
                >
                  <Input.Password prefix={<LockOutlined />} placeholder={t('password')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
            <Col span={12}>
                <Form.Item
                  name="username"
                  label={t('username')}
                  rules={[{ required: true, message: t('pleaseInputUsername') }]}
                >
                  <Input prefix={<UserOutlined />} placeholder={t('username')} />
                </Form.Item>
              </Col>
            <Col span={12}>
                <Form.Item
                  name="role"
                  label={t('role')}
                  rules={[{ required: true, message: t('pleaseSelectRole') }]}
                >
                  <Select placeholder={t('selectRole')}>
                    <Option value="user">{t('user')}</Option>
                    <Option value="admin">{t('admin')}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Button type="primary" htmlType="submit" style={{ marginRight: '10px' }}>
              {t('addUser')}
            </Button>
            <Button onClick={handleAddUserCancel}>{t('cancel')}</Button>
          </Form>
          </Modal>

          {/* Modify User Modal */}
  <Modal
    title={<div style={{ marginBottom: '30px' }}>{t('modifyUser')}</div>}
    open={modifyUserVisible}
    onCancel={() => setModifyUserVisible(false)}
    footer={null}
    width={800}
  >
  <Form
    form={modifyForm}
    name="modify_user"
    onFinish={handleModifyUser}
    layout="vertical"
  >
    <Form.Item name="id" hidden>
      <Input />
    </Form.Item>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name="firstname"
          label={t('firstName')}
          rules={[{ required: true, message: t('pleaseInputFirstName') }]}
        >
          <Input prefix={<UserOutlined />} placeholder={t('firstName')} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="lastname"
          label={t('lastName')}
          rules={[{ required: true, message: t('pleaseInputLastName') }]}
        >
          <Input prefix={<UserOutlined />} placeholder={t('lastName')} />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name="email"
          label={t('email')}
          rules={[
            { required: true, message: t('pleaseInputEmail') },
            { type: 'email', message: t('invalidEmail') }
          ]}
        >
          <Input prefix={<MailOutlined />} placeholder={t('email')} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="username"
          label={t('username')}
          rules={[{ required: true, message: t('pleaseInputUsername') }]}
        >
          <Input prefix={<UserOutlined />} placeholder={t('username')} />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name="role"
          label={t('role')}
          rules={[{ required: true, message: t('pleaseSelectRole') }]}
        >
          <Select placeholder={t('selectRole')}>
            <Option value="user">{t('user')}</Option>
            <Option value="admin">{t('admin')}</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="is_blocked"
          label={t('userStatus')}
          rules={[{ required: true, message: t('pleaseSelectStatus') }]}
        >
          <Select placeholder={t('selectStatus')} style={{ height: "50px"}}>
            <Option value="false">{t('active')}</Option>
            <Option value="true">{t('blocked')}</Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name="password"
          label={t('password')}
          rules={[{ required: false, message: t('pleaseInputPassword') }]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder={t('leaveBlankToKeepCurrent')} />
        </Form.Item>
      </Col>
    </Row>
    <Button type="primary" htmlType="submit" style={{ marginRight: '10px' }}>
      {t('saveChanges')}
    </Button>
    <Button onClick={() => setModifyUserVisible(false)}>{t('cancel')}</Button>
  </Form>
</Modal>
        </div>
      </Content>
    </Layout>
  );
};

// Styles
const layoutStyle = {
  minHeight: "100vh",
  padding: "20px",
};

const contentStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  width: "100%",
  height: "100%",
};

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  height: "150px",
};

export default UserTable;
