import { EditOutlined, DeleteOutlined, PlusOutlined , ArrowLeftOutlined} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  message,
  Layout,
  Pagination
} from "antd";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Content } = Layout;

const JurisprudenceList = () => {
  const { t } = useTranslation();
  const [jurisprudences, setJurisprudences] = useState([
    {
      id: 1,
      nomFichier: "Document1.pdf",
      url: "https://example.com/document1.pdf",
      filePath: "/documents/Document1.pdf",
      datePublication: "2024-01-01",
    },
    {
      id: 2,
      nomFichier: "Document2.pdf",
      url: "https://example.com/document2.pdf",
      filePath: "/documents/Document2.pdf",
      datePublication: "2024-02-01",
    },   {
      id: 3,
      nomFichier: "Document1.pdf",
      url: "https://example.com/document1.pdf",
      filePath: "/documents/Document1.pdf",
      datePublication: "2024-01-01",
    },
    {
      id: 4,
      nomFichier: "Document2.pdf",
      url: "https://example.com/document2.pdf",
      filePath: "/documents/Document2.pdf",
      datePublication: "2024-02-01",
    },
    {
      id: 5,
      nomFichier: "Document1.pdf",
      url: "https://example.com/document1.pdf",
      filePath: "/documents/Document1.pdf",
      datePublication: "2024-01-01",
    },
    {
      id: 6,
      nomFichier: "Document2.pdf",
      url: "https://example.com/document2.pdf",
      filePath: "/documents/Document2.pdf",
      datePublication: "2024-02-01",
    },
  ]);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingJurisprudence, setEditingJurisprudence] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8; // Number of cards per page


  const showModal = () => setVisible(true);

  const handleCancel = () => {
    setVisible(false);
    setEditingJurisprudence(null);
    form.resetFields();
  };

  const handleCreate = () => {
    form
      .validateFields()
      .then((values) => {
        const newJurisprudence = {
          ...values,
          id: jurisprudences.length + 1,
          datePublication: dayjs(values.datePublication).format("YYYY-MM-DD"),
        };
        setJurisprudences([...jurisprudences, newJurisprudence]);
        message.success(t("visitorPage.successMessage"));
        form.resetFields();
        setVisible(false);
      })
      .catch((error) => {
        message.error(t("visitorPage.error.formSubmission"));
        console.error(t("visitorPage.error.formSubmission"), error);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedJurisprudences = jurisprudences.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleEdit = (jurisprudence) => {
    setEditingJurisprudence(jurisprudence);
    form.setFieldsValue(jurisprudence);
    setVisible(true);
  };

  const handleUpdate = () => {
    form
      .validateFields()
      .then((values) => {
        const updatedJurisprudences = jurisprudences.map((juris) =>
          juris.id === editingJurisprudence.id
            ? { ...values, id: editingJurisprudence.id }
            : juris
        );
        setJurisprudences(updatedJurisprudences);
        message.success(t("visitorPage.successMessage"));
        form.resetFields();
        setVisible(false);
      })
      .catch((error) => {
        message.error(t("visitorPage.error.formSubmission"));
        console.error(t("visitorPage.error.formSubmission"), error);
      });
  };

  const handleDelete = (id) => {
    setJurisprudences(jurisprudences.filter((juris) => juris.id !== id));
    message.success(t("visitorPage.successMessage"));
  };

  return (
    <Layout style={layoutStyle}>
      <Content style={contentStyle}>
        <div style={{ width: "100%", padding: "20px" }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <Button>
              <Link to="/settings">
                <ArrowLeftOutlined
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    color: "black"
                  }}
                />
              </Link>
            </Button>
            <Title level={2} style={{ paddingLeft: "50px" }}>{t("jurisprudences")}</Title>
          </div>
          <Card>
            <Button onClick={showModal} style={{ marginBottom: "20px" }}>
              <PlusOutlined /> {t("addJurisprudence")}
            </Button>
            <Row gutter={[16, 16]}>
              {paginatedJurisprudences.map((jurisprudence) => (
                <Col key={jurisprudence.id} xs={24} sm={12} md={8} lg={6}>
                  <Card
                    hoverable
                    actions={[
                      <EditOutlined onClick={() => handleEdit(jurisprudence)} />,
                      <DeleteOutlined onClick={() => handleDelete(jurisprudence.id)} />,
                    ]}
                  >
                    <Card.Meta
                      title={jurisprudence.nomFichier}
                      description={
                        <>
                          <p>{t("url")}: <a href={jurisprudence.url} target="_blank" rel="noopener noreferrer">{jurisprudence.url}</a></p>
                          <p>{t("filePath")}: {jurisprudence.filePath}</p>
                          <p>{t("datePublication")}: {jurisprudence.datePublication}</p>
                        </>
                      }
                    />
                  </Card>
                </Col>
              ))}
            </Row>
            <Pagination
              current={currentPage}
              total={jurisprudences.length}
              pageSize={pageSize}
              onChange={handlePageChange}
              style={{ marginTop: "20px", textAlign: "center" }}
            />
          </Card>
        </div>
      </Content>
      <Modal
        open={visible}
        title={editingJurisprudence ? t("editJurisprudence") : t("addJurisprudence")}
        onCancel={handleCancel}
        onOk={editingJurisprudence ? handleUpdate : handleCreate}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="nomFichier"
            label={t("nomFichier")}
            rules={[{ required: true, message: t("enterSearchTerm") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="url"
            label={t("url")}
            rules={[{ required: true, message: t("enterSearchTerm") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="filePath"
            label={t("filePath")}
            rules={[{ required: true, message: t("enterSearchTerm") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="datePublication"
            label={t("datePublication")}
            rules={[{ required: true, message: t("selectDate") }]}
          >
            <Input type="date" />
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

// Styles
const layoutStyle = {
  minHeight: "100vh",
  padding: "20px",
};

const contentStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  width: "100%",
  height: "100%",
};

export default JurisprudenceList;
