import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    eng: {
      translation: require("./translations/en.json"),
    },
    fr: {
      translation: require("./translations/fr.json"),
    },
  },
  lng: "fr", // Default language
  fallbackLng: "fr",
  interpolation: { escapeValue: false },
});

export default i18n;
