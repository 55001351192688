import { DeleteOutlined, PlusOutlined , ArrowLeftOutlined } from "@ant-design/icons";
import { 
  Layout, 
  Typography, 
  Button, 
  Table, 
  Modal, 
  Form, 
  Select, 
  message, 
  Image,
  Col,
  Card
} from "antd";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosInstance";
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { Content } = Layout;
const { Title } = Typography;


const DocumentModelList = () => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState([]);
  const [models, setModels] = useState([]);
  const [documentModels, setDocumentModels] = useState([]);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchDocuments();
    fetchModels();
    fetchDocumentModels();
  }, []);

  const fetchDocuments = async () => {
    try {
      const response = await axiosInstance.get("/document/documents/active");
      setDocuments(response.data.documents);
    } catch (error) {
      console.error(t("error.fetchDocuments"), error);
    }
  };

  const fetchModels = async () => {
    try {
      const response = await axiosInstance.get("/model/models");
      setModels(response.data.models);
    } catch (error) {
      console.error(t("error.fetchModels"), error);
    }
  };

  const fetchDocumentModels = async () => {
    try {
      const response = await axiosInstance.get(
        "/documentmodel/document_models"
      );
      setDocumentModels(response.data.document_models);
    } catch (error) {
      console.error(t("error.fetchDocumentModels"), error);
    }
  };

  const columns = [
    {
      title: t("document"),
      dataIndex: "document_id",
      key: "document_id",
      render: (document_id) => {
        const document = documents.find((doc) => doc.id === document_id);
        return document ? document.name : "";
      },
    },
    {
      title: t("model"),
      dataIndex: "model_id",
      key: "model_id",
      render: (model_id) => {
        const model = models.find((mod) => mod.id === model_id);
        return model ? model.modelname : "";
      },
    },
    {
      title: t("actions"),
      dataIndex: "",
      key: "actions",
      render: (text, record) => (
        <Button
          danger
          onClick={() => handleDelete(record.document_id, record.model_id)}
        >
          <DeleteOutlined />
          {t("delete")}
        </Button>
      ),
    },
  ];

  const showModal = () => setVisible(true);

  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      await axiosInstance.post("/documentmodel/document_models", values);
      message.success(t("success.createDocumentModel"));
      fetchDocumentModels();
      form.resetFields();
      setVisible(false);
    } catch (error) {
      message.error(t("error.createDocumentModel"));
      console.error(t("error.createDocumentModel"), error);
    }
  };

  const handleDelete = async (document_id, model_id) => {
    try {
      await axiosInstance.delete(
        `/documentmodel/document_models/${document_id}/${model_id}`
      );
      message.success(t("success.deleteDocumentModel"));
      fetchDocumentModels();
    } catch (error) {
      message.error(t("error.deleteDocumentModel"));
      console.error(t("error.deleteDocumentModel"), error);
    }
  };

  return (
    <Layout style={layoutStyle}>
      {/* <Col flex="0 1 auto">
        <Link to="/">
          <Image
            src="/logo/logo.png"
            alt="MFI Logo"
            preview={false}
            style={{
              height: "50px",
              width: "auto",
              marginTop: "10px",
            }}
          />
        </Link>
      </Col> */}
      <Content style={contentStyle}>
        <div style={{ width: "100%", padding: "20px" }}>
        <div style={{ display: 'flex' , alignItems: 'center' , alignItems: 'center', marginBottom: '20px'}}>
            <Button>
              <Link to="/settings">
                <ArrowLeftOutlined
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    color:"black"
                  }}
                />
              </Link>
            </Button>
          <Title level={2} style={{paddingLeft:"50px"}}>{t('documentModel')}</Title>
          </div>
          <Card>
            <Button onClick={showModal} style={{ marginBottom: "20px" }}>
              <PlusOutlined />
              {t("addDocumentModelLink")}
            </Button>
            <Table dataSource={documentModels} columns={columns} />
          </Card>
        </div>
      </Content>
      <Modal
        visible={visible}
        title={t("addDocumentModelLink")}
        onCancel={handleCancel}
        onOk={handleCreate}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="document_id"
            label={t("document")}
            rules={[
              { required: true, message: t("selectDocument") },
            ]}
          >
            <Select>
              {documents.map((document) => (
                <Option key={document.id} value={document.id}>
                  {document.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="model_id"
            label={t("model")}
            rules={[
              { required: true, message: t("selectModel") },
            ]}
          >
            <Select>
              {models.map((model) => (
                <Option key={model.id} value={model.id}>
                  {model.modelname}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

// Styles
const layoutStyle = {
  minHeight: "100vh",
  padding: "20px",
};

const contentStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  width: "100%",
  height: "100%",
};

export default DocumentModelList;