import { Modal } from "antd";
import DOMPurify from "dompurify"; // For secure HTML sanitization
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import {Typography} from "antd"

const { Text } = Typography;

const HtmlModal = ({ visible, onClose, htmlFile, item, lang }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null); // Store error message
  const [fetchedHtml, setFetchedHtml] = useState(null);
  const iframeRef = useRef(null);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(null); // Reset error on new fetch
    console.log("html modal info.", visible, onClose, htmlFile, item, lang);
    const fetchHTML = async () => {
      try {
        let combinedHtml = "";
        // ... (Fetch logic similar to original)
        if (htmlFile) {
          if (htmlFile.length > 1) {
            const htmlContents = await Promise.all(
              htmlFile.map(async (fileName) => {
                const response = await fetch(
                  `/${lang === "fr" ? "datahtmllois" : "englaw"}/${fileName}`
                );
                if (!response.ok) {
                  throw new Error(`Failed to fetch ${fileName}`);
                }
                return await response.text();
              })
            );
            combinedHtml = htmlContents.join("");
          } else {
            const response = await fetch(
              `/${lang === "fr" ? "datahtmllois" : "englaw"}/${htmlFile[0]}`
            );
            combinedHtml = await response.text();
          }
        }
        const sanitizedHtml = DOMPurify.sanitize(combinedHtml); // Sanitize fetched HTML

        const htmlWithoutLinks = sanitizedHtml.replace(/<a\b[^>]*>(.*?)<\/a>/gi, "$1");

        setFetchedHtml(htmlWithoutLinks); // Update fetched content
      } catch (error) {
        console.error("Error fetching HTML files:", error);
        setError(error.message); // Set user-friendly error message
      } finally {
        setLoading(false);
      }
    };

    fetchHTML();
  }, [htmlFile, lang]);
  

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };  

  function stringToArray(str) {
    if (Array.isArray(str)) {
      return str;
    }

    if (str.startsWith("[") && str.endsWith("]")) {
      const arr = str.slice(1, -1).split(",");
      return arr.map((item) => item.trim().replace(/^'(.*)'$/, "$1"));
    }

    if (str.startsWith("")) {
      return str.split(",");
    }

    throw new Error("Invalid input format");
  }

  const highlightElements = () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      const contentWindow = iframeRef.current.contentWindow;
      const iframeDocument = contentWindow.document;

      console.log("highlighting ...///", item);

      const outputArray = stringToArray(item.hyperlink);

      console.log("highlighting after format", outputArray);

      if (Array.isArray(outputArray)) {
        let elementToScroll = null;

        for (let i = 0; i < outputArray.length; i++) {
          const element = outputArray[i];
          const targetElement = iframeDocument.getElementById(element);
          console.log("targetElement///", targetElement);

          if (targetElement) {
            targetElement.style.backgroundColor = "yellow";

            if (element !== null && element !== "None") {
              elementToScroll = targetElement;
              break;
            }
          }
        }

        if (elementToScroll) {
          elementToScroll.scrollIntoView();
        }
      } else {
        const targetElement = iframeDocument.getElementById(outputArray);

        if (targetElement) {
          targetElement.style.backgroundColor = "yellow";
          targetElement.scrollIntoView();
        }
      }
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300); // Match this with the CSS transition duration
  };

  if (!visible && !isClosing) return null;

  const modalContent = (
    <div
      onClick={handleClose}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        opacity: isClosing ? 0 : 1,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          backgroundColor: 'white',
          borderRadius: '8px',
          width: '90%',
          height: '90%',
          maxWidth: '90vw',
          maxHeight: '90vh',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          transform: isClosing ? 'scale(0.9)' : 'scale(1)',
          opacity: isClosing ? 0 : 1,
          transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid #f0f0f0',
          }}
        >
          <Text style={{ margin: 0 , paddingLeft:"10px", fontSize: 20, fontWeight: "normal"}}>{t("articleTitle")}</Text>
          <button
            onClick={handleClose}
            style={{
              background: 'none',
              border: 'none',
              fontSize: '20px',
              cursor: 'pointer',
            }}
          >
            ×
          </button>
        </div>
        <div style={{ flex: 1, overflow: 'auto', padding: '20px' }}>
          {loading ? (
            <p>{t("loading")}</p>
          ) : error ? (
            <p>{t("error", { error })}</p>
          ) : (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  aspectRatio: '2.2 / 1',
                }}
              >
                <iframe
                  title="Article Viewer"
                  ref={iframeRef}
                  srcDoc={fetchedHtml}
                  onLoad={highlightElements}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return createPortal(modalContent, document.body);
};

export default HtmlModal;