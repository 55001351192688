import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Layout, Row, Typography, Image } from "antd";
import { useTranslation } from "react-i18next";
import {
  ApiOutlined,
  ArrowLeftOutlined,
  BgColorsOutlined,
  FileTextOutlined,
  RobotOutlined,
  WalletOutlined,
  UsergroupAddOutlined,
  BookOutlined
} from "@ant-design/icons";

const { Content } = Layout;
const { Title } = Typography;

const Settings = () => {
  const { t } = useTranslation();

  const bentoBoxes = [
    // {
    //   key: "appearance",
    //   icon: <BgColorsOutlined style={iconStyle} />,
    //   title: t('appearance'),
    //   path: "/settings/appearance",
    // },
    {
      key: "documentlist",
      icon: <FileTextOutlined style={iconStyle} />,
      title: t('documents'),
      path: "/settings/documents",
    },
    {
      key: "modellist",
      icon: <RobotOutlined style={iconStyle} />,
      title: t('models'),
      path: "/settings/models",
    },
    {
      key: "documentmodellist",
      icon: <ApiOutlined style={iconStyle} />,
      title: t('documentModel'),
      path: "/settings/document-models",
    },
    {
      key: "filters",
      icon: <ApiOutlined style={iconStyle} />,
      title: t('filters'),
      path: "/settings/filters",
    },
    // {
    //   key:"knowledgetable",
    //   icon: <WalletOutlined style={iconStyle} />,
    //   title: t("KnowledgeTable"),
    //   path: "/settings/knowledge"
    // },
    {
      key:"Users",
      icon: <UsergroupAddOutlined style={iconStyle} />,
      title: t("userManagment"),
      path: "/settings/users"
    },
    // {
    //   key:"jurisprudance",
    //   icon: <BookOutlined style={iconStyle} />,
    //   title: t("jurisprudences"),
    //   path: "/settings/jurisprudance"
    // },
  ];

  return (
    <Layout style={layoutStyle}>
      {/* <Col flex="0 1 auto">
        <Link to="/">
          <Image
            src="/logo/logo.png"
            alt="MFI Logo"
            preview={false}
            style={{
              height: "50px",
              width: "auto",
              marginTop: "10px",
            }}
          />
        </Link>
      </Col> */}
      <Content style={contentStyle}>
        <Row gutter={[16, 16]} style={rowStyle}>
          {bentoBoxes.map((box) => (
            <Col key={box.key} xs={24} sm={12} md={8} lg={6}>
              <Link to={box.path}>
                <Card hoverable style={cardStyle}>
                  {box.icon}
                  <Title level={4}>{box.title}</Title>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Content>
    </Layout>
  );
};

// Styles remain the same
const layoutStyle = {
  minHeight: "85vh",
  padding: "20px",
  minWidth:"100vh"
};

const contentStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  height: "100%",
};

const rowStyle = {
  width: "100%",
  maxWidth: "1200px",
  margin: "0 auto",
};

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  height: "150px",
};

const iconStyle = {
  fontSize: "36px",
  marginBottom: "10px",
};

export default Settings;