import React, { useRef, useState, useEffect } from 'react';
import { Avatar, Tooltip, Card, Menu } from 'antd';
import { HistoryOutlined, SettingOutlined, LogoutOutlined, ProfileOutlined ,UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axiosInstance from "../axiosInstance";

const UserAvatar = ({ handleLogout }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [username, setUsername] = useState('');
  const dropdownRef = useRef(null);
  const avatarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        avatarRef.current &&
        !avatarRef.current.contains(event.target)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axiosInstance.get('/auth/current_user');
        const user_details={
          username: response.data.username,
          firstName: response.data.firstname,
          lastName: response.data.lastname,
          email: response.data.email,
          role: response.data.role
        }
        localStorage.setItem('user', JSON.stringify(user_details));
        setUsername(response.data.firstname + " "+response.data.lastname);
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const getUser = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  };

  console.log(getUser().username)

  const CustomDropdown = () => (
    <Card
      ref={dropdownRef}
      style={{
        width: 300,
        position: 'absolute',
        top: '60px',
        right: '0px',
        zIndex: 9999,
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      }}
    >
      <div style={{ padding: '10px', borderBottom: '1px solid #f0f0f0' }}>
        <Avatar
          size={'large'}
          style={{
            backgroundColor: '#dda15e',
            color: '#fefae0',
            marginRight: '16px',
          }}
        >
          {username ? username[0].toUpperCase() : '?'}
        </Avatar>
        <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
          {username}
        </span>
      </div>
      <Menu mode="vertical" style={{ border: 'none' }} onClick={() => setDropdownVisible(false)}>
      <Menu.Item key="profile" icon={<UserOutlined />}>
          <Link to="/profile">{t('profile')}</Link>
        </Menu.Item>
        <Menu.Item key="questions" icon={<HistoryOutlined />}>
          <Link to="/questions">{t('questionsHistory')}</Link>
        </Menu.Item>
        {getUser().role.name === "admin" && (
        <Menu.Item key="settings" icon={<SettingOutlined />}>
          <Link to="/settings">{t('settings')}</Link>
        </Menu.Item>
        )}
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined />}
          onClick={handleLogout}
          danger
        >
          {t('logout')}
        </Menu.Item>
      </Menu>
    </Card>
  );

  const tooltipContent = (
    <div>
      <p style={{ margin: 0 }}>{username}</p>
    </div>
  );

  return (
    <div>
      <Tooltip title={tooltipContent} placement="bottomRight">
        <Avatar
          ref={avatarRef}
          size={'large'}
          style={{
            backgroundColor: '#dda15e',
            color: '#fefae0',
            marginLeft: '16px',
            cursor: 'pointer',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.3)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.boxShadow = 'none';
          }}
          onClick={toggleDropdown}
        >
          {username ? username[0].toUpperCase() : '?'}
        </Avatar>
      </Tooltip>
      {dropdownVisible && <CustomDropdown />}
    </div>
  );
};

export default UserAvatar;
