import { SearchOutlined } from "@ant-design/icons";
import { Form, Input, Layout, Select, Typography } from "antd";

import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../axiosInstance";
import customContexts from "../services/customContexts";

const { Option } = Select;
const { Title, Paragraph, Text } = Typography;
const { Sider, Content } = Layout;

const SearchComponent = ({
  onSearch,
  loading,
  fromhomequery,
  setFromhomequery,
  lang,
}) => {
  const { t } = useTranslation();
  const [referralCode, setReferralCode] = useState("");
  const [documents, setDocuments] = useState([]);
  const [models, setModels] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [filteredFiltersString, setfilteredFiltersString] = useState("");
  const [groupBy, setGroupBy] = useState("date");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedValues, setSelectedValues] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [selectedDisplayedValues, setSelectedDisplayedValues] = useState(null);
  const [form] = Form.useForm();
  const [filterwithValues, setFilterwithValues] = useState(null);
  const { authenticated, customColors, Lang } = useContext(customContexts);
  const [speechInput, setSpeechInput] = useState("");
  const recognitionRef = useRef();

  const handleSpeechStart = () => {
    if (isListening) {
      recognitionRef.current?.stop();
      setIsListening(false);
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) {
      alert(t("searchComponent.speechNotSupported"));
      return;
    }

    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.lang = "fr-CA";

    recognitionRef.current.onstart = function () {
      setIsListening(true);
    };

    recognitionRef.current.onend = function () {
      setIsListening(false);
    };

    recognitionRef.current.onresult = async function (event) {
      const transcript = event.results[0][0].transcript;
      setSpeechInput(transcript);
      setReferralCode(transcript);
      console.log("event", event);
    };

    recognitionRef.current.start();
  };

  const handleChangefiltervalue = (filterId, value, typefilter, date) => {
    setSelectedDisplayedValues({
      ...selectedDisplayedValues,
      [filterId]: date,
    });
    console.log(filterId, value, typefilter);

    if (typefilter === "date") {
      setSelectedValues({
        ...selectedValues,
        [filterId]: value,
      });
    } else if (typefilter === "year") {
      setSelectedValues({
        ...selectedValues,
        [filterId]: value,
      });
    } else {
      setSelectedValues({ ...selectedValues, [filterId]: value });
    }
  };

  useEffect(() => {
    fetchDocuments();
    fetchfilterwithvalues();
    console.log("Lang::::", lang);
  }, []);

  const fetchDocuments = async () => {
    try {
      await axiosInstance.get("/document/documents/active").then((response) => {
        setDocuments(response.data.documents);

        if (response.data.documents.length) {
          response.data.documents.forEach((doc) => {
            if (lang === "eng") {
              if (doc.type.toLowerCase().includes("canada,eng")) {
                console.log("doc:", doc);
                setSelectedDocument(doc.id);
                return;
              }
            } else {
              if (doc.type.toLowerCase().includes("canada,fr")) {
                console.log("doc:", doc);

                setSelectedDocument(doc.id);
                return;
              }
            }
          });
        }
      });
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const fetchfilterwithvalues = async () => {
    try {
      const response = await axiosInstance.get("/filter/filters-with-values");
      setFilterwithValues(response.data.filters);
      console.log("Filters with values////", response.data.filters);
    } catch (error) {
      console.error("Error fetching filters:", error);
    }
  };

  const fetchDocumentsmodels = async () => {
    if (selectedDocument) {
      await axiosInstance
        .get(`/documentmodel/document_models/${selectedDocument}`)
        .then((response) => {
          setModels(response.data.document_models);
        })
        .catch((error) => {
          console.error("Error fetching models:", error);
        });
    }
  };

  useEffect(() => {
    fetchDocumentsmodels();
  }, [selectedDocument]);

  const handleSearch = () => {
    console.log(
      "handleSearch",
      referralCode,
      selectedDocument,
      selectedModel,
      groupBy,
      filteredFiltersString
    );
    onSearch(
      referralCode,
      selectedDocument,
      selectedModel,
      filteredFiltersString,
      groupBy
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleDocumentChange = (value) => {
    setSelectedDocument(value);
  };

  const handleModelChange = (value) => {
    setSelectedModel(value);
  };

  const handleGroupByChange = (value) => {
    setGroupBy(value);
  };

  const handleModalOk = async () => {
    const filteredFilters = {};

    for (const key in selectedValues) {
      if (Object.hasOwnProperty.call(selectedValues, key)) {
        const value = selectedValues[key];
        const filter = filterwithValues.find(
          (filter) => filter.id.toString() === key
        );

        if (filter) {
          if (typeof value === "string") {
            filteredFilters[key] = { ...filter, filter_values: [{ value }] };
          } else {
            const filteredValues = filter.filter_values.filter(
              (filterValue) => {
                if (Array.isArray(value)) {
                  return value.includes(filterValue.id);
                } else {
                  return filterValue.id === value;
                }
              }
            );
            const filteredFilter = { ...filter, filter_values: filteredValues };
            filteredFilters[key] = filteredFilter;
          }
        }
      }
    }

    setfilteredFiltersString(JSON.stringify(filteredFilters));
    console.log("Filtered Filters:", filteredFilters);
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Input
      prefix={<SearchOutlined />}
      placeholder={t("searchComponent.inputPlaceholder")}
      value={speechInput || (fromhomequery ? fromhomequery : referralCode)}
      onKeyDown={handleKeyPress}
      onChange={(e) => {
        setFromhomequery(null);
        setReferralCode(e.target.value);
        setSpeechInput("");
      }}
      size="large"
      style={{
        width: "70%",
        lineHeight: "28px",
        padding: "0 1rem",
        border: `1px solid ${customColors.royalBlue.primary}`,
        borderRadius: "8px",
        outline: "none",
        backgroundColor: "#ffffff",
        color: "#0d0c22",
        transition: ".3s ease",
      }}
      allowClear
    />

    //   ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    //     This section allows the user to enter the query vocally
    //   ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    //   <Button
    //     type="text"
    //     iconPosition={"start"}
    //     onClick={handleSpeechStart}
    //     icon={isListening ? <AudioFilled /> : <AudioOutlined />}
    //     style={{
    //       backgroundColor: isListening ? "red" : "black",
    //       color: "white",
    //       marginRight: "8px",
    //     }}
    //   >
    //     {isListening ? t("searchComponent.stopRecording") : t("searchComponent.startRecording")}
    //   </Button>

    //   ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    //     Manually search button
    //   ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    //   <Button
    //     type="primary"
    //     onClick={handleSearch}
    //     loading={loading}
    //     disabled={!referralCode}
    //   >
    //     <SearchOutlined />
    //     {t("searchComponent.searchButton")}
    //   </Button>
    //   <Modal
    //     open={isModalVisible}
    //     onOk={handleModalOk}
    //     onCancel={handleModalCancel}
    //     footer={[
    //       <Button
    //         key="reset"
    //         onClick={() => {
    //           setSelectedValues(null);
    //           setSelectedDisplayedValues(null);
    //         }}
    //       >
    //         {t("searchComponent.resetButton")}
    //       </Button>,
    //       <Button key="submit" type="primary" onClick={handleModalOk}>
    //         {t("searchComponent.submitButton")}
    //       </Button>,
    //     ]}
    //   >
    //     <Form form={form} layout="vertical">
    //       {filterwithValues &&
    //         filterwithValues.map((filter) => (
    //           <Form.Item key={filter.id} label={filter.name}>
    //             {filter.typefilter === "date" && (
    //               <DatePicker
    //                 value={
    //                   selectedDisplayedValues
    //                     ? selectedDisplayedValues[filter.id]
    //                     : null
    //                 }
    //                 onChange={(date, dateString) =>
    //                   handleChangefiltervalue(
    //                     filter.id,
    //                     dateString,
    //                     filter.typefilter,
    //                     date
    //                   )
    //                 }
    //               />
    //             )}
    //             {filter.typefilter === "year" && (
    //               <DatePicker
    //                 picker="year"
    //                 value={
    //                   selectedDisplayedValues
    //                     ? selectedDisplayedValues[filter.id]
    //                     : null
    //                 }
    //                 onChange={(date, dateString) =>
    //                   handleChangefiltervalue(
    //                     filter.id,
    //                     dateString,
    //                     filter.typefilter
    //                   )
    //                 }
    //               />
    //             )}
    //             {filter.typefilter !== "date" &&
    //               filter.typefilter !== "year" && (
    //                 <Select
    //                   value={
    //                     selectedValues ? selectedValues[filter.id] || [] : []
    //                   }
    //                   showSearch={false}
    //                   onChange={(value) =>
    //                     handleChangefiltervalue(
    //                       filter.id,
    //                       value,
    //                       filter.typefilter
    //                     )
    //                   }
    //                   mode={filter.ismultiple ? "multiple" : null}
    //                 >
    //                   {filter.filter_values.map((value) => (
    //                     <Select.Option key={value.id} value={value.id}>
    //                       {value.value}
    //                     </Select.Option>
    //                   ))}
    //                 </Select>
    //               )}
    //           </Form.Item>
    //         ))}
    //     </Form>
    //   </Modal>

    // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    // This section allows the user to select a document to search on, and filters applied to the query
    // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    // <div style={{ display: "flex", justifyContent: "center" }}>
    //   <Select
    //     placeholder={t("searchComponent.selectDocumentPlaceholder")}
    //     style={{ width: 450, marginRight: "8px" }}
    //     onChange={handleDocumentChange}
    //     value={
    //       selectedDocument || (documents.length > 0 ? documents[0].id : null)
    //     }
    //   >
    //     {documents.map((document) => (
    //       <Option key={document.id} value={document.id}>
    //         {document.name}
    //       </Option>
    //     ))}
    //   </Select>
    //   {!["jack", "benoit"].includes(authenticated) && (
    //     <Select
    //       placeholder={t("searchComponent.selectModelPlaceholder")}
    //       style={{ width: 200, marginRight: "8px" }}
    //       onChange={handleModelChange}
    //       disabled={!selectedDocument}
    //     >
    //       {models.map((model) => (
    //         <Option key={model.model_id} value={model.model_id}>
    //           {model.model_name}
    //         </Option>
    //       ))}
    //     </Select>
    //   )}
    //   <Button
    //     onClick={() => setIsModalVisible(true)}
    //     className="card-with-border"
    //     style={{
    //       width: 100,
    //       marginLeft: "8px",
    //       height: "32px",
    //       borderColor: customColors.royalBlue.primary,
    //     }}
    //   >
    //     <Badge
    //       offset={[18, -8]}
    //       color="black"
    //       count={
    //         Object.keys(selectedValues ? selectedValues : {}).filter(
    //           (key) => {
    //             const value = selectedValues[key];
    //             if (Array.isArray(value)) {
    //               return value.length > 0;
    //             } else {
    //               return (
    //                 value !== "" && value !== null && value !== undefined
    //               );
    //             }
    //           }
    //         ).length
    //       }
    //     >
    //       <FilterOutlined style={{ marginRight: "4px" }} />
    //       {t("searchComponent.filtersButton")}
    //     </Badge>
    //   </Button>
    // </div>
  );
};

export default SearchComponent;
