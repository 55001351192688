import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { 
  Layout, 
  Typography, 
  Button, 
  Form, 
  Col, 
  Row, 
  ColorPicker, 
  Image,
  Card
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import customContexts from "../services/customContexts";
import { useTranslation } from 'react-i18next';

const { Content } = Layout;
const { Title } = Typography;

const AppearancePage = () => {
  const { t } = useTranslation();
  const { customColors, colorchosen, setAppColors, appColors } = useContext(customContexts);

  return (
    <Layout style={layoutStyle}>
      {/* <Col flex="0 1 auto">
        <Link to="/">
          <Image
            src="/logo/logo.png"
            alt="MFI Logo"
            preview={false}
            style={{
              height: "50px",
              width: "auto",
              marginTop: "10px",
            }}
          />
        </Link>
      </Col> */}
      <Content style={contentStyle}>
        <div style={{ width: "100%", padding: "20px" }}>
          <Link to="/settings">
            <ArrowLeftOutlined
              style={{
                fontSize: "24px",
                cursor: "pointer",
                marginBottom: "20px",
                color:"black"
              }}
            />
          </Link>
          <Card>
            <Title level={2}>{t('appearanceSettings')}</Title>
            <Button type="primary" style={{ marginBottom: "20px" }}>
              {t('button')}
            </Button>
            
            <Form
              layout="vertical"
              onValuesChange={(_, allValues) => {
                setAppColors({
                  ...allValues,
                });
              }}
              name="theme"
              initialValues={appColors}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name={["Button", "colorPrimary"]}
                    label={t('primaryColor')}
                    trigger="onChangeComplete"
                    getValueFromEvent={(color) => color.toHexString()}
                  >
                    <ColorPicker />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </Content>
    </Layout>
  );
};

// Styles
const layoutStyle = {
  minHeight: "100vh",
  padding: "20px",
};

const contentStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  width: "100%",
  height: "100%",
};

export default AppearancePage;