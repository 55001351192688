import { DeleteOutlined, EditOutlined, PlusOutlined , ArrowLeftOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  message,
  Layout,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosInstance";
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { Title } = Typography;
const { Content } = Layout;

const DocumentList = () => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState([]);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingDocument, setEditingDocument] = useState(null);

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const response = await axiosInstance.get("/document/documents");
      setDocuments(response.data.documents);
    } catch (error) {
      console.error(t("documentList.errors.fetchDocuments"), error);
    }
  };

  const showModal = () => setVisible(true);

  const handleCancel = () => {
    setVisible(false);
    setEditingDocument(null);
    form.resetFields();
  };

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      values.date = dayjs(values.date).format("YYYY-MM-DD");
      values.status = values.status.toString();

      await axiosInstance.post("/document/documents", values);
      message.success(t("documentList.messages.createSuccess"));

      fetchDocuments();
      form.resetFields();
      setVisible(false);
    } catch (error) {
      message.error(t("documentList.errors.createDocument"));
      console.error(t("documentList.errors.createDocument"), error);
    }
  };

  const handleEdit = (document) => {
    const { date, ...formData } = document;
    const formattedDate = dayjs(date);
    setEditingDocument({ ...formData, date: formattedDate });
    form.setFieldsValue({ ...formData, date: formattedDate });
    setVisible(true);
  };

  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
      values.date = dayjs(values.date).format("YYYY-MM-DD");
      values.status = values.status.toString();

      await axiosInstance.put(
        `/document/documents/${editingDocument.id}`,
        values
      );
      message.success(t("documentList.messages.updateSuccess"));
      fetchDocuments();
      form.resetFields();
      setVisible(false);
    } catch (error) {
      console.error(t("documentList.errors.updateDocument"), error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/document/documents/${id}`);
      message.success(t("documentList.messages.deleteSuccess"));
      fetchDocuments();
    } catch (error) {
      console.error(t("documentList.errors.deleteDocument"), error);
    }
  };

  return (
    <Layout style={layoutStyle}>
      <Content style={contentStyle}>
        <div style={{ width: "100%", padding: "20px" }}>
        <div style={{ display: 'flex' , alignItems: 'center' , alignItems: 'center', marginBottom: '20px'}}>
            <Button>
              <Link to="/settings">
                <ArrowLeftOutlined
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    color:"black"
                  }}
                />
              </Link>
            </Button>
          <Title level={2} style={{paddingLeft:"50px"}}>{t("documentList.title")}</Title>
        </div>
          <Card>
            <Button onClick={showModal} style={{ marginBottom: "20px" }}>
              <PlusOutlined /> {t("documentList.addDocument")}
            </Button>
            <Row gutter={[16, 16]}>
              {documents.map((document) => (
                <Col key={document.id} xs={24} sm={12} md={8} lg={6}>
                  <Card
                    hoverable
                    actions={[
                      <EditOutlined onClick={() => handleEdit(document)} />,
                      <DeleteOutlined onClick={() => handleDelete(document.id)} />,
                    ]}
                  >
                    <Card.Meta
                      title={document.name}
                      description={
                        <>
                          <p>{t("documentList.type")}: {document.type}</p>
                          <p>{t("documentList.date")}: {document.date}</p>
                          <p>
                            {t("documentList.status")}:{" "}
                            {document.status === "not_active" ? (
                              <Badge status="error" text={t("documentList.statusInactive")} />
                            ) : document.status === "under_development" ? (
                              <Badge status="processing" text={t("documentList.statusDevelopment")} />
                            ) : (
                              <Badge status="success" text={t("documentList.statusActive")} />
                            )}
                          </p>
                          <p>{t("documentList.language")}: {document.langue}</p>
                        </>
                      }
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </div>
      </Content>
      <Modal
        open={visible}
        title={editingDocument ? t("documentList.editDocument") : t("documentList.addDocument")}
        onCancel={handleCancel}
        onOk={editingDocument ? handleUpdate : handleCreate}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label={t("documentList.formName")}
            rules={[{ required: true, message: t("documentList.formNameRequired") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="type"
            label={t("documentList.formType")}
            rules={[{ required: true, message: t("documentList.formTypeRequired") }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="date"
            label={t("documentList.formDate")}
            rules={[
              { required: true, message: t("documentList.formDateRequired") },
            ]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item
            name="status"
            label={t("documentList.formStatus")}
            rules={[
              { required: true, message: t("documentList.formStatusRequired") },
            ]}
          >
            <Select>
              <Option value="active">{t("documentList.statusActive")}</Option>
              <Option value="not_active">{t("documentList.statusInactive")}</Option>
              <Option value="under_development">{t("documentList.statusDevelopment")}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="langue"
            label={t("documentList.formLanguage")}
            rules={[
              { required: true, message: t("documentList.formLanguageRequired") },
            ]}
          >
            <Select>
              <Option value="fr">FR</Option>
              <Option value="eng">ENG</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

// Styles
const layoutStyle = {
  minHeight: "100vh",
  padding: "20px",
};

const contentStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  width: "100%",
  height: "100%",
};

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  height: "150px",
};

export default DocumentList;