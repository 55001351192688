import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { 
  Layout, 
  Typography, 
  Button, 
  Modal, 
  Form, 
  Input, 
  Select,
  Table,
  Tag, 
  message, 
  Image,
  Empty,
  Col,
  Card,
  Row
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
  CloseOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import axiosInstance from "../axiosInstance";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const { Content } = Layout;
const { Title } = Typography;

const FilterList = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState([]);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingFilter, setEditingFilter] = useState(null);
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState(null);
  const [filterValues, setFilterValues] = useState([]);
  const [visible1, setVisible1] = useState(false);
  const [editingFilterValue, setEditingFilterValue] = useState(null);
  const [type, setType] = useState("text");
  const [isMultipleDisabled, setIsMultipleDisabled] = useState(false);
  const [isFilterSelected, setIsFilterSelected] = useState(false);

  useEffect(() => {
    fetchFilters();
  }, []);

  const handletypefilterchange = (value) => {
    if (value === "Date" || value === "Year") {
      form.setFieldsValue({ ismultiple: false });
      setIsMultipleDisabled(true);
    } else {
      setIsMultipleDisabled(false);
    }
  };

  // Methods for filter values part
  const showModal1 = () => setVisible1(true);
  const handleCancel1 = () => {
    setVisible1(false);
    setEditingFilterValue(null);
    form2.resetFields();
  };

  const fetchFilterValues = async (id) => {
    try {
      const response = await axiosInstance.get(`/filter/filters/${id}/values`);
      setFilterValues(response.data.filter_values);
    } catch (error) {
      console.error(t('errorFiltre.fetchFilterValues'), error);
    }
  };

  const handleCreate1 = async () => {
    try {
      const values = await form2.validateFields();
      await axiosInstance.post(
        `/filter/filters/${selectedRowKeys}/values`,
        values
      );
      message.success(t('success.createFilterValue'));
      fetchFilterValues(selectedRowKeys);
      form2.resetFields();
      setVisible1(false);
    } catch (error) {
      message.error(t('errorFiltre.createFilterValue'));
      console.error(t('errorFiltre.createFilterValue'), error);
    }
  };

  const handleDelete1 = async (id) => {
    try {
      await axiosInstance.delete(`/filter/filter-values/${id}`);
      message.success(t('success.deleteFilterValue'));
      fetchFilterValues(selectedRowKeys);
    } catch (error) {
      console.error(t('errorFiltre.deleteFilterValue'), error);
    }
  };

  const handleEdit1 = (filterValue) => {
    setEditingFilterValue(filterValue);
    form2.setFieldsValue(filterValue);
    setVisible1(true);
  };

  const handleUpdate1 = async () => {
    try {
      const values = await form2.validateFields();
      await axiosInstance.put(
        `/filter/filter-values/${editingFilterValue.id}`,
        values
      );
      message.success(t('success.updateFilterValue'));
      fetchFilterValues(selectedRowKeys);
      setEditingFilterValue(null);
      form2.resetFields();
      setVisible1(false);
    } catch (error) {
      console.error(t('errorFiltre.updateFilterValue'), error);
    }
  };

  const valuescolumns = [
    { title: t('filterValue'), dataIndex: "value", key: "value" },
    {
      title: t('actions'),
      dataIndex: "",
      key: "actions",
      render: (text, record) => (
        <Button.Group>
          <Button type="dashed" onClick={() => handleEdit1(record)}>
            <EditOutlined />
            {t('edit')}
          </Button>
          <Button danger onClick={() => handleDelete1(record.id)}>
            <DeleteOutlined />
            {t('delete')}
          </Button>
        </Button.Group>
      ),
    },
  ];

  // Methods for filters part
  const fetchFilters = async () => {
    try {
      const response = await axiosInstance.get("/filter/filters");
      setFilters(response.data.filters);
    } catch (error) {
      console.error(t('errorFiltre.fetchFilters'), error);
    }
  };

  const columns = [
    { title: t('id'), dataIndex: "id", key: "id", width: 100 , fixed: "left", },
    { title: t('name'), dataIndex: "name", key: "name" },
    { title: t('type'), dataIndex: "typefilter", key: "typefilter" },
    {
      title: t('isMultiple'),
      dataIndex: "ismultiple",
      key: "ismultiple",
      render: (ismultiple) => {
        if (ismultiple === true) {
          return <Tag color="green">{t('yes')}</Tag>;
        } else if (ismultiple === false) {
          return <Tag color="red">{t('no')}</Tag>;
        }
      },
    },
    { title: t('date'), dataIndex: "date", key: "date" },
    {
      title: t('actions'),
      dataIndex: "",
      key: "actions",
      width: 250,
      fixed: "right",
      render: (text, record) => (
        <Button.Group>
          <Button type="dashed" onClick={() => handleEdit(record)}>
            <EditOutlined />
            {t('edit')}
          </Button>
          <Button danger onClick={() => handleDelete(record.id)}>
            <DeleteOutlined />
            {t('delete')}
          </Button>
        </Button.Group>
      ),
    },
  ];

  const showModal = () => setVisible(true);

  const handleCancel = () => {
    setVisible(false);
    setEditingFilter(null);
    form.resetFields();
  };

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      values.date = dayjs(values.date).format("YYYY-MM-DD");
      await axiosInstance.post("/filter/filters", values);
      message.success(t('success.createFilter'));
      fetchFilters();
      form.resetFields();
      setVisible(false);
    } catch (error) {
      message.error(t('errorFiltre.createFilter'));
      console.error(t('errorFiltre.createFilter'), error);
    }
  };

  const handleEdit = (filter) => {
    const { date, ...formData } = filter;
    const formattedDate = dayjs(date);
    setEditingFilter({ ...formData, date: formattedDate });
    form.setFieldsValue({ ...formData, date: formattedDate });
    setVisible(true);
  };

  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
      values.date = dayjs(values.date).format("YYYY-MM-DD");
      await axiosInstance.put(`/filter/filters/${editingFilter.id}`, values);
      message.success(t('success.updateFilter'));
      fetchFilters();
      form.resetFields();
      setEditingFilter(null);
      setVisible(false);
    } catch (error) {
      console.error(t('errorFiltre.updateFilter'), error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/filter/filters/${id}`);
      message.success(t('success.deleteFilter'));
      fetchFilters();
    } catch (error) {
      console.error(t('errorFiltre.deleteFilter'), error);
    }
  };

  const deselectRow = () => {
    setSelectedRowKeys(null);
    setIsFilterSelected(false);
    setFilterValues([]);
  };

  // Update the rowSelection object
  const rowSelection = {
    type: "radio",
    selectedRowKeys: selectedRowKeys ? [selectedRowKeys] : [],
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRowKeys.length > 0) {
        const selectedRow = selectedRows[0];
        if (selectedRow.typefilter === "text") {
          setSelectedRowKeys(selectedRowKeys[0]);
          setIsFilterSelected(true);
          fetchFilterValues(selectedRowKeys[0]);
        } else {
          deselectRow();
        }
      } else {
        deselectRow();
      }
    },
  };

  return (
    <Layout style={layoutStyle}>
      {/* <Col flex="0 1 auto">
        <Link to="/">
          <Image
            src="/logo/logo.png"
            alt="MFI Logo"
            preview={false}
            style={{
              height: "50px",
              width: "auto",
              marginTop: "10px",
            }}
          />
        </Link>
      </Col> */}
      <Content style={contentStyle}>
        <div style={{ width: "100%", padding: "20px" }}>
          <div style={{ display: 'flex' , alignItems: 'center' , alignItems: 'center', marginBottom: '20px'}}>
            <Button>
              <Link to="/settings">
                <ArrowLeftOutlined
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    color:"black"
                  }}
                />
              </Link>
            </Button>
            <Title level={2} style={{paddingLeft:"50px"}}>{t('filters')}</Title>
          </div>
          <Row gutter={20}>
            <Col span={isFilterSelected ? 14 : 24}>
              <Card>
                <Button onClick={showModal} style={{ marginBottom: "20px" }}>
                  <PlusOutlined />
                  {t('addFilter')}
                </Button>
                <Table
                  locale={{
                    emptyText: <Empty description={t('noData')} />,
                  }}
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={filters}
                  rowKey="id"
                  scroll={{ x: 1300 }}
                  className="responsive-table"
                />
              </Card>
            </Col>
            {isFilterSelected && (
              <Col span={10}>
                <Card>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <h2 style={{ margin: 0 }}>{t('filterValues')}</h2>
                    <CloseOutlined
                      onClick={deselectRow}
                      style={{ cursor: 'pointer', fontSize: '16px' }}
                    />
                  </div>
                  <Button onClick={showModal1} style={{ marginBottom: "20px", marginRight: "10px" }}>
                    <PlusOutlined />
                    {t('addFilterValue')}
                  </Button>
                  <Table dataSource={filterValues} columns={valuescolumns} scroll={{ x: 300 }} className="responsive-table" />
                </Card>
              </Col>
            )}
          </Row>
        </div>
      </Content>
      <Modal
        open={visible}
        title={editingFilter ? t('editFilter') : t('addFilter')}
        onCancel={handleCancel}
        onOk={editingFilter ? handleUpdate : handleCreate}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label={t('name')}
            rules={[{ required: true, message: t('enterName') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="typefilter"
            label={t('type')}
            rules={[{ required: true, message: t('selectType') }]}
          >
            <Select onChange={(value) => handletypefilterchange(value)}>
              <Option value={"Text"}>{t('text')}</Option>
              <Option value={"Date"}>{t('date')}</Option>
              <Option value={"Year"}>{t('year')}</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="ismultiple"
            label={t('isMultiple')}
            rules={[{ required: true, message: t('selectMultiple') }]}
          >
            <Select disabled={isMultipleDisabled}>
              <Option value={true}>{t('yes')}</Option>
              <Option value={false}>{t('no')}</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <div>
        <Modal
          open={visible1}
          title={
            editingFilterValue
              ? t('editFilterValue')
              : t('addFilterValue')
          }
          onCancel={handleCancel1}
          onOk={editingFilterValue ? handleUpdate1 : handleCreate1}
        >
          <Form form={form2} layout="vertical">
            <Form.Item
              name="value"
              label={t('value')}
              rules={[{ required: true, message: t('enterValue') }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
        </div>
    </Layout>
  );
};


// Styles
const layoutStyle = {
  minHeight: "100vh",
  padding: "20px",
};

const contentStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  flexDirection: "column",
  width: "100%",
  height: "100%",
};


export default FilterList;
