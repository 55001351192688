import React, { useState, useEffect } from "react";
import { Layout, Typography, Row, Col, Card, Avatar, Menu, Spin } from "antd";
import { useTranslation } from "react-i18next";
import PersonalInformation from "../components/PersonalInformation";
import ChangePassword from "../components/ChangePassword";
import axiosInstance from "../axiosInstance";

const { Title, Text } = Typography;
const { Content } = Layout;

const ProfilePage = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('1');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axiosInstance.get('/auth/current_user');
        setUser({
          name: `${response.data.firstname} ${response.data.lastname}`.trim(),
          firstName: response.data.firstname,
          lastName: response.data.lastname,
          email: response.data.email,
          role: response.data.role || "User"
        });
        setLoading(false);
      } catch (error) {
        console.error(t('fetchUserError'), error);
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [t]);

  const getUser = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  };

  console.log(getUser().username);

  const renderContent = () => {
    if (loading) {
      return (
        <Layout>
          <Content style={{ padding: '24px', textAlign: 'center' }}>
            <Spin size="large" />
          </Content>
        </Layout>
      );
    }

    if (!user) {
      return (
        <Layout>
          <Content style={{ padding: '24px', textAlign: 'center' }}>
            <Text>{t('failedToLoadUser')}</Text>
          </Content>
        </Layout>
      );
    }

    return (
      <Layout>
        <Content style={{ padding: '24px' }}>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Card style={{ textAlign: 'center' }}>
                <Avatar
                  size={100}
                  style={{
                    backgroundColor: "#dda15e",
                    color: "#fefae0",
                    marginBottom: '16px',
                    fontSize: '40px'
                  }}
                >
                  {user.name && typeof user.name === 'string' ? user.name[0].toUpperCase() : '?'}
                </Avatar>
                <Title level={4}>{typeof user.name === 'string' ? user.name : t('notAvailable')}</Title>
                <Text>{getUser().role.name}</Text>
              </Card>
            </Col>
            <Col span={18}>
              <Card>
                <Menu 
                  mode="horizontal" 
                  selectedKeys={[activeTab]} 
                  onClick={(e) => setActiveTab(e.key)}
                  style={{ marginBottom: '24px' }}
                >
                  <Menu.Item key="1">{t('personalInformation')}</Menu.Item>
                  <Menu.Item key="2">{t('changePassword')}</Menu.Item>
                </Menu>
                {activeTab === '1' ? (
                  <PersonalInformation user={user} setUser={setUser} />
                ) : (
                  <ChangePassword />
                )}
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  };

  return renderContent();
};

export default ProfilePage;