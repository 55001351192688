import React, { useState } from "react";
import { Typography, Row, Col, Card, Input, Button, message } from "antd";
import { useTranslation } from "react-i18next";
import axiosInstance from "../axiosInstance";

const { Text } = Typography;

const PersonalInformation = ({ user, setUser }) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState(user);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setEditedUser(user);
    setIsEditing(false);
  };

  const handleSave = async () => {
    try {
      const response = await axiosInstance.put('auth/update_profile', {
        firstname: editedUser.firstName,
        lastname: editedUser.lastName,
        email: editedUser.email
      });
      
      if (response.status === 200) {
        setUser(editedUser);
        setIsEditing(false);
        message.success(t('profileUpdateSuccess'));
      }
    } catch (error) {
      console.error(t('profileUpdateError'), error);
      message.error(t('profileUpdateFailed'));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const renderValue = (value) => {
    if (typeof value === 'string') {
      return value;
    }
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return t('notAvailable');
  };

  return (
    <Card title={t('personalInformation')}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Text strong>{t('firstName')}:</Text>
          <Input
            name="firstName"
            value={renderValue(editedUser.firstName)}
            readOnly={!isEditing}
            onChange={handleInputChange}
          />
        </Col>
        <Col span={12}>
          <Text strong>{t('lastName')}:</Text>
          <Input
            name="lastName"
            value={renderValue(editedUser.lastName)}
            readOnly={!isEditing}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
        <Col span={24}>
          <Text strong>{t('emailAddress')}:</Text>
          <Input
            name="email"
            value={renderValue(editedUser.email)}
            readOnly={!isEditing}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
        <Col span={24} style={{ textAlign: 'right' }}>
          {!isEditing ? (
            <Button onClick={handleEdit}>{t('edit')}</Button>
          ) : (
            <>
              <Button onClick={handleCancel} style={{ marginRight: '8px' }}>
                {t('cancel')}
              </Button>
              <Button
                onClick={handleSave}
                type="primary"
                style={{
                  backgroundColor: "#F83E29",
                  borderColor: "white",
                  color: "white",
                }}
              >
                {t('save')}
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default PersonalInformation;